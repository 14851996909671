<template>
    <div class="card asset-preview">
        <div class="card-body p-0">
            <div class="m-3" style="position: relative">
                <div class="row no-gutters">
                    <div class="col-5">
                        <div
                            class="thumbnail"
                            @click="viewProof()"
                            style="cursor: pointer"
                            v-b-tooltip.hover
                            :title="$root.text('View details', null, true)"
                        >
                            <img :src="image_src" :alt="title">
                        </div>
                    </div>
                    <div class="col pl-3">
                        <div class="title mb-2 text-dark font-size-15" :id="'proof-title' + id" @click="viewProof()">
                            {{ short_title }}
                        </div>
                        <b-tooltip
                            v-if="(short_title != title)"
                            :target="'proof-title' + id"
                            triggers="hover"
                        >
                            {{ title }}
                        </b-tooltip>

                        <div v-if="chart_data" class="float-right" style="position: absolute; top: 48px; left: 0px">
                            <apex-stroked-gauge-chart
                                :percent="chart_data.percent"
                                :dash_array="2"
                                :width="chart_data.width"
                                :height="chart_data.height"
                                :title="chart_data.title"
                                :subtitle="chart_data.subtitle"
                            ></apex-stroked-gauge-chart>
                        </div>

                        <div class="team-avatars" :style="'width: calc(100% - ' + (chart_data ? 115 : 15) + 'px)'">
                            <div v-if="(team1.length > 0)">
                                <div v-if="leader_index1 !== null" style="height: 23px; position: relative">
                                    <span
                                        :style="'position: relative; left: ' + (leader_index1 * 22 + 3) + 'px'"
                                    >
                                        <i class="fal fa-crown gold font-size-18"></i>
                                    </span>
                                </div>
                                <div v-else style="height: 5px"></div>
                                <div style="height: 32px; position: relative">
                                    <div
                                        v-for="item in team1"
                                        :key="id + '-user-' + item.user_id"
                                        class="mr-1"
                                        style="display: inline-block"
                                    >
                                        <div
                                            v-if="item.profile_image"
                                            :key="id + '-user-image-' + item.user_id"
                                            class="rounded-circle avatar-xs bordered"
                                            :style="'left: ' + (item.index * 22 - 3) + 'px'"
                                            v-b-tooltip.hover
                                            :title="$root.text(item.leader ? 'Leader'
                                                : (item.reviewer ? 'Reviewer' : 'Watcher'), null, true)"
                                        >
                                            <img class="rounded-circle avatar-xs" :src="item.profile_image" alt="avatar">
                                        </div>
                                        <div
                                            v-else
                                            :key="id + '-user-initial-' + item.user_id"
                                            class="rounded-circle avatar-xs bordered"
                                            :style="'left: ' + (item.index * 22 - 3) + 'px'"
                                            v-b-tooltip.hover
                                            :title="$root.text(item.leader ? 'Leader'
                                                : (item.reviewer ? 'Reviewer' : 'Watcher'), null, true)"
                                        >
                                            <span class="avatar-title rounded-circle">
                                                {{ $root.initial(item.first_name, item.last_name) }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="(team2.length > 0)">
                                <div v-if="leader_index2 !== null" style="height: 23px; position: relative">
                                    <span
                                        :style="'position: relative; left: ' + (leader_index2 * 22 + 3) + 'px'"
                                    >
                                        <i class="fal fa-crown gold font-size-18"></i>
                                    </span>
                                </div>
                                <div v-else style="height: 5px"></div>
                                <div style="height: 32px; position: relative">
                                    <div
                                        v-for="item in team2"
                                        :key="id + '-user-' + item.user_id"
                                        class="mr-1"
                                        style="display: inline-block"
                                    >
                                        <div
                                            v-if="item.profile_image"
                                            :key="id + '-user-image-' + item.user_id"
                                            class="rounded-circle avatar-xs bordered"
                                            :style="'left: ' + (item.index * 22 - 3) + 'px'"
                                            v-b-tooltip.hover
                                            :title="$root.text(item.leader ? 'Leader'
                                                : (item.reviewer ? 'Reviewer' : 'Watcher'), null, true)"
                                        >
                                            <img class="rounded-circle avatar-xs" :src="item.profile_image" alt="avatar">
                                        </div>
                                        <div
                                            v-else
                                            :key="id + '-user-initial-' + item.user_id"
                                            class="rounded-circle avatar-xs bordered"
                                            :style="'left: ' + (item.index * 22 - 3) + 'px'"
                                            v-b-tooltip.hover
                                            :title="$root.text(item.leader ? 'Leader'
                                                : (item.reviewer ? 'Reviewer' : 'Watcher'), null, true)"
                                        >
                                            <span class="avatar-title rounded-circle">
                                                {{ $root.initial(item.first_name, item.last_name) }}
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        v-if="(team2.length < (proof.users.length - team1.length))"
                                        class="rounded-circle avatar-xs bordered"
                                        :style="'left: ' + (team2.length * 22 - 3) + 'px'"
                                        v-b-tooltip.hover
                                        :title="$root.text('View team', null, true)"
                                    >
                                        <router-link :to="{ name: 'view-proof-tab', params: { id: id, tab: 'team' } }">
                                            <span class="avatar-title rounded-circle bg-light text-dark">
                                                +{{ proof.users.length - team1.length - team2.length }}
                                            </span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="mb-0">
            <div class="mx-3" style="margin-top: 12px; margin-bottom: 12px; min-height: 25px">
                <span v-if="approved" class="badge badge-success font-size-11" style="padding: 7px">
                    {{ $root.text('Completed', null, true) }}
                    <span v-if="proof">{{ formatDate(proof.approved_at) }}</span>
                </span>
                <span v-if="!approved && suspended && proof">
                    <span
                        v-if="isLeader() && (proof.number_of_reviewed >= proof.number_of_reviewers)"
                        class="badge badge-light font-size-11"
                        style="padding: 7px"
                    >{{ $root.text('Ready for next cycle', null, true) }}</span>
                    <span
                        v-else
                        class="badge badge-danger font-size-11"
                        style="padding: 7px"
                    >{{ $root.text('Suspended', null, true) }}</span>
                </span>
                <span v-if="!suspended && !approved && proof">
                    <span v-if="canReview() && !proof.in_use">
                        <button
                            v-if="!downloading"
                            :key="'review-button'"
                            type="button"
                            class="btn btn-primary btn-sm"
                            @click="reviewProof()"
                        >{{ $root.text('Download and review', null, true) }}</button>
                        <button v-else :key="'downloading-button'" type="button" class="btn btn-primary btn-sm">
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">{{ $root.text('Downloading', null, true) }}</span>
                        </button>
                    </span>
                    <button
                        v-if="canReview() && proof.in_use"
                        :key="'return-button'"
                        type="button"
                        class="btn btn-primary btn-sm"
                        data-toggle="modal"
                        data-target="#proof-return-modal"
                        @click="returnProof()"
                    >{{ $root.text('Return this proof', null, true) }}</button>
                    <span v-if="!canReview()">
                        <span
                            v-if="proof.number_of_reviewed >= proof.number_of_reviewers"
                            class="badge badge-light font-size-11"
                            style="padding: 7px"
                        >{{ $root.text('Cycle complete', null, true) }}</span>
                        <span v-else>
                            <span
                                v-if="!proof.in_use || (isReviewer() === 0)"
                                class="badge badge-light font-size-11"
                                style="padding: 7px"
                            >
                                <span v-if="isReviewer() === 0">{{ $root.text('Await notification', null, true) }}</span>
                                <span v-else>
                                    {{ hasReturned() || 'Awaiting next reviewer' }}
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
                <span v-if="proof" class="float-right">
                    <span v-if="!canReview() && proof.in_use && !proof.suspended && !proof.archived">
                        <span
                            class="badge bg-secondary text-secondary font-size-11"
                            style="padding: 7px; margin-right: 24px"
                        >&nbsp;&nbsp;{{ $root.text('In use', null, true) }}&nbsp;&nbsp;</span>
                        <div style="display: inline-block; position: relative">
                            <div
                                v-if="getReviewerProfileImage()"
                                :key="'reviewer-profile-image'"
                                class="rounded-circle avatar-xs"
                                :style="'position: absolute; top: -20px; left: -32px; width: 34px; height: 34px; '
                                    + 'border: 2px solid #fff'"
                                v-b-tooltip.hover
                                :title="getReviewerName()"
                            >   
                                <img class="rounded-circle avatar-xs" :src="getReviewerProfileImage()" alt="avatar">
                            </div>
                            <div
                                v-else
                                :key="'reviewer-initials'"
                                class="rounded-circle avatar-xs"
                                :style="'position: absolute; top: -20px; left: -32px; width: 34px; height: 34px; '
                                    + 'border: 2px solid #fff'"
                                v-b-tooltip.hover
                                :title="getReviewerName()"
                            >
                                <span class="avatar-title rounded-circle">
                                    {{ getReviewerInitials() }}
                                </span>
                            </div>
                        </div>
                    </span>
                    <span
                        v-if="proof.proof_folder_id && !$parent.isFolderView()"
                        class="contact-links ml-2"
                        style="position: relative; top: 4px"
                    >
                        <a
                            href="#"
                            @click.prevent="viewFolder(proof.proof_folder_id)"
                            v-b-tooltip.hover
                            :title="((proof.number_of_proofs_in_folder == 1)
                                ? $root.text('There is 1 proof in the folder.', null, true)
                                : $root.text('There are {x} proofs in the folder.',
                                { x: proof.number_of_proofs_in_folder }, true)) + ' '
                                + $root.text('Click here to view.', null, true)"
                        >
                            <i class="far fa-folder font-size-18"></i> {{ proof.number_of_proofs_in_folder }}
                        </a>
                    </span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data'],
        data () {
            return {
                id: 0,
                image_src: '/images/file-icon.png',
                title: '',
                short_title: '',
                suspended: false,
                approved: false,
                proof: null,
                team1: [],
                team2: [],
                leader_index1: null,
                leader_index2: null,
                chart_data: null,
                downloading: false,
                window_width: 0,
                check_width_interval: null
            }
        },
        mounted () {
            if (this.data) {
                this.updateData(this.data);
            }
            var vm = this;
            $(window).resize(function () {
                vm.setWidth();
            });
            vm.check_width_interval = setInterval(function () {
                if (vm.window_width != $(window).width()) {
                    vm.setWidth();
                }
            }, 1010);
        },
        beforeDestroy() {
            clearInterval(this.check_width_interval);
        },
        watch: {
            data: function (val) {
                if (val) {
                    this.updateData(val);
                }
            }
        },
        methods: {
            setWidth: function () {
                this.window_width = $(window).width();
                var vm = this;
                vm.short_title = vm.title;
                setTimeout(function () {
                    vm.fitTitle();
                    vm.updateTeam();
                }, 500);
            },
            updateData: function (data) {
                this.id = data.id;
                this.title = data.title;
                this.short_title = data.title;
                this.suspended = data.suspended;
                this.approved = data.approved;
                this.getProofDetails();
                this.getPreviewImage();
                var vm = this;
                setTimeout(function () {
                    vm.fitTitle();
                }, 500);
            },
            fitTitle: function () {
                if (!$('#proof-title' + this.id)[0]) {
                    var vm = this;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 200);

                    return null;
                }
                if ($('#proof-title' + this.id)[0].scrollHeight > 45) {
                    let words = this.short_title.split(' ');
                    words.pop();
                    if (words.length > 0) {
                        this.short_title = words.join(' ') + '...';
                        var vm = this;
                        setTimeout(function () {
                            vm.fitTitle();
                        }, 200);
                    }
                }
            },
            getProofDetails: function () {
                this.chart_data = null;
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/' + this.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.proof = response.data.proof;
                    if (!vm.proof.suspended && (vm.proof.number_of_reviewers > 0)/* && vm.isLeader()*/) {
                        vm.chart_data = {
                            percent: ((vm.proof.number_of_reviewers == 0) || (vm.proof.number_of_reviewed == 0))
                                ? 0 : Math.floor((vm.proof.number_of_reviewed / vm.proof.number_of_reviewers) * 100),
                            width: 110,
                            height: 100,
                            title: {
                                text: vm.proof.number_of_reviewed + '/' + vm.proof.number_of_reviewers,
                                font_family: 'Poppins',
                                font_size: '20px',
                                font_weight: 700,
                                colour: vm.$root.client.third_colour,
                                top: 7
                            },
                            subtitle: {
                                text: vm.$root.text('Cycle {x}', { x: vm.proof.version }, true),
                                font_size: '13px',
                                font_weight: 400,
                                colour: '#495057',
                                top: 72
                            }
                        };
                    }
                    vm.updateTeam();
                })
                .catch(function (error) {
                    // Show any errors?
                });
            },
            updateTeam: function () {
                if (!this.proof) {
                    this.team1 = [];
                    this.team2 = [];

                    return null;
                }
                let width = $('#proof-title' + this.proof.id).innerWidth() || 0;
                if (!width) {
                    this.team1 = [];
                    this.team2 = [];

                    return null;
                }
                if (this.chart_data) {
                    width -= 90;
                }

                // Line 1.
                let maxAvatars = Math.floor((width - 15) / 22);
                let team = [];
                this.leader_index1 = null;
                let index = 0;
                this.proof.users.forEach((item) => {
                    if (index < maxAvatars) {
                        item.index = index;
                        team.push(item);
                        if (item.leader) {
                            this.leader_index1 = index;
                        }
                        index++;
                    }
                });
                if (team.length != this.team1.length) {
                    this.team1 = team;
                    this.team2 = [];
                }
                if (this.team1.length == this.proof.users.length) {
                    return null;
                }
                // Line 2.
                maxAvatars = Math.floor((width - 15) / 22);
                if ((this.proof.users.length - this.team1.length) > maxAvatars) {
                    maxAvatars--;
                }
                team = [];
                this.leader_index2 = null;
                index = 0;
                this.proof.users.forEach((item, itemIndex) => {
                    if ((itemIndex >= this.team1.length) && (index < maxAvatars)) {
                        item.index = index;
                        team.push(item);
                        if (item.leader) {
                            this.leader_index2 = index;
                        }
                        index++;
                    }
                });

                if (team.length != this.team2.length) {
                    this.team2 = team;
                }
            },
            getPreviewImage: function () {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/' + this.id + '/preview';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src = response.data.image_src;
                    }
                })
                .catch(function (error) {
                    // Show any errors?
                });
            },
            viewProof: function (tab = null) {
                if (tab) {
                    this.$router.push({
                        name: 'view-proof-tab',
                        params: {
                            id: this.id,
                            tab: tab
                        }
                    });

                    return null;
                }
                this.$router.push({
                    name: 'view-proof',
                    params: {
                        id: this.id
                    }
                });
            },
            isLeader: function () {
                if (this.proof.users.length == 0) {
                    return false;
                }
                let found = false;
                this.proof.users.forEach((item) => {
                    if (item.leader && (item.user_id == this.$root.user.id)) {
                        found = true;
                    }
                });

                return found;
            },
            isReviewer: function () {
                if (this.proof.users.length == 0) {
                    return false;
                }
                let alreadyReviewed = 0;
                let reviewer = false;
                let reviewedStatuses = ['AP', 'NC', 'WC', 'MC', 'SK'];
                this.proof.users.forEach((item) => {
                    if (item.user_id == this.$root.user.id) {
                        if (item.reviewer) {
                            reviewer = true;
                        }
                        if (reviewer && item.review_status && (reviewedStatuses.indexOf(item.review_status) > -1)) {
                            alreadyReviewed = 1;
                        }
                    }
                });

                if (!reviewer) {
                    return false;
                }

                return alreadyReviewed;
            },
            hasReturned: function () {
                let returnStatus = false;
                this.proof.users.forEach((item) => {
                    if ((item.user_id == this.$root.user.id) && item.reviewer) {
                        if (item.review_status == 'WC') {
                            returnStatus = this.$root.text('You returned with changes', null, true);
                        }
                        if ((item.review_status == 'NC') || (item.review_status == 'MC')) {
                            returnStatus = this.$root.text('You approved this', null, true);
                        }
                    }
                });

                return returnStatus;
            },
            canReview: function () {
                if (this.suspended || this.approved || !this.proof) {
                    return false;
                }
                if (this.proof.archived) {
                    return false;
                }
                if (this.proof.in_use) {
                    if (!this.proof.current_reviewer) {
                        return false;
                    }

                    return (this.proof.current_reviewer.user_id == this.$root.user.id);
                }
                if (this.proof.ordered) {
                    if (!this.proof.next_reviewer) {
                        return false;
                    }

                    return (this.proof.next_reviewer.user_id == this.$root.user.id);
                }

                return (this.isReviewer() === 0);
            },
            reviewProof: function () {
                if (!this.proof) {
                    return null;
                }
                if (!this.proof.file) {
                    return null;
                }
                this.downloading = true;
                /**
                 * Send request to API.
                 */
                const download = this.proof.file;
                const data = {
                    item_type: 'proof_review',
                    item_id: this.proof.id,
                    file_path: download.file_path,
                    file_type: download.file_type,
                    download_filename: download.filename + '.' + download.extension
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/request-download-token';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    const url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/download/'
                        + response.data.token;
                    window.location.href = url;
                    vm.proof = null;
                    vm.getProofDetails();
                })
                .catch(function (error) {
                    let appComponent = vm.$root.findComponent(vm.$root, 'main-app');
                    appComponent.message = {
                        type: 'error',
                        title: vm.$root.text('Review proof', null, true),
                        text: error.response.data.error
                            || vm.$root.text('Something went wrong, please try again', null, true)
                    };
                    $('#message-modal').modal('show');
                })
                .finally(() =>
                    vm.downloading = false
                );
            },
            viewFolder: function () {
                if (!this.proof.proof_folder_id || this.$parent.loading) {
                    return null;
                }
                let filters = [];
                this.$root.proof_filters.forEach((item) => {
                    if ((item.key == 'archived') || (item.key == 'user') || (item.key == 'sort_by')) {
                        filters.push(item);
                    }
                });
                this.$root.proof_filters = filters;
                this.$root.addFilter('proof_filters', {
                    key: 'folder',
                    value: this.proof.proof_folder_id,
                    text: this.proof.folder_name.replace(/"/g, '&quot;').replace(/'/g, '&apos;'),
                    filters_key: 'proof_filters'
                });
                this.$parent.getProofs();
            },
            returnProof: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'proof-return');
                if (component) {
                    component.reset(this.proof);
                }
            },
            hasComments: function () {
                if (!this.proof) {
                    return false;
                }

                return (this.proof.number_of_comments > 0);
            },
            getReviewerProfileImage: function () {
                if (!this.proof.current_reviewer && !this.proof.next_reviewer) {
                    return null;
                }
                const userId = this.proof.current_reviewer ? this.proof.current_reviewer.user_id
                    : this.proof.next_reviewer.user_id;
                let profileImage = null;
                this.proof.users.forEach((item) => {
                    if (item.user_id == userId) {
                        profileImage = item.profile_image;
                    }
                });

                return profileImage;
            },
            getReviewerName: function () {
                if (!this.proof.current_reviewer && !this.proof.next_reviewer) {
                    return '';
                }
                return this.proof.current_reviewer ? this.proof.current_reviewer.name
                    : this.proof.next_reviewer.name;
            },
            getReviewerInitials: function () {
                if (!this.proof.current_reviewer && !this.proof.next_reviewer) {
                    return '';
                }
                const userId = this.proof.current_reviewer ? this.proof.current_reviewer.user_id
                    : this.proof.next_reviewer.user_id;
                let initials = '';
                this.proof.users.forEach((item) => {
                    if (item.user_id == userId) {
                        initials = this.$root.initial(item.first_name, item.last_name);
                    }
                });

                return initials;
            },
            formatDate: function (rawDate) {
                if (!rawDate) {
                    return '';
                }
                const date = rawDate.substr(0, 10).split('-');

                return date[2] + '/' + date[1] + '/' + date[0];
            }
        }
    }
</script>
<style scoped>
    .asset-preview .thumbnail {
        height: 150px;
        overflow: hidden;
        border-top-left-radius: unset;
        border-top-right-radius: unset;
    }

    .asset-preview .thumbnail img {
        max-height: 148px;
    }

    .asset-preview .card-body .title {
        height: 38px;
        line-height: 18px;
        font-weight: 500;
        cursor: pointer;
        overflow-wrap: anywhere;
    }

    .team-avatars {
        position: absolute;
        top: 50px;
        right: 0px;
        height: 100px;
    }

    .avatar-xs.bordered {
        position: absolute;
        width: 34px;
        height: 34px;
        top: -5px;
        font-size: 12px;
        transition: top .35s ease;
    }

    .avatar-xs.bordered {
        border: 2px solid #fff;
    }

    .avatar-xs.bordered:hover {
        top: -7px !important;
        z-index: 2;
    }

    .avatar-xs img {
        width: 30px;
        height: 30px;
    }

    .fa-crown.gold {
        color: #bf9000;
    }
</style>
