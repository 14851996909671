<template>
    <div class="account-pages mx-md-5 my-5 pt-sm-5">
        <div class="container-fluid">
            <div :class="$root.loginPanelClass()">
                <div class="col-sm-6 col-lg-4 col-xl-3 login-panel-col">
                    <div class="card overflow-hidden">
                        <div :class="$root.app.login_panel_header_class + ' px-4 py-2 text-center'">
                            <img
                                v-if="$root.app.login_logo"
                                :src="$root.app.login_logo"
                                :alt="$root.client.client_name"
                                class="img-fluid"
                            >
                        </div>
                        <div class="card-body pt-2 pb-1">
                            <div v-if="$root.getAvailableLanguages().length > 1" class="text-right m-2">
                                <language-select></language-select>
                            </div>

                            <div class="p-2">
                                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                                <div v-if="loading"><loading></loading></div>

                                <div v-if="(!loading && updated)">
                                    <div class="alert alert-success" role="alert">
                                        <div class="row">
                                            <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                                            <div class="col" v-html="updated"></div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="(!loading && (errors || updated))" class="form-group text-center">
                                    <router-link :to="{ name: 'login' }">
                                        <i class="far fa-sign-in-alt mr-1"></i>
                                        {{ $root.text('Click here to login', null, true) }}
                                    </router-link>
                                </div>

                                <div class="text-center" style="font-size: 0.7em; position: relative; height: 10px">
                                    {{ $root.text('Powered by', null, true) }}
                                    <a href="https://resourcebase.com" target="_blank">Resourcebase</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                loading: true,
                errors: false,
                updated: false
            }
        },
        mounted () {
            $('body').addClass('login-body');
            this.verifyEmail();
        },
        methods: {
            verifyEmail: function () {
                this.loading = true;
                this.errors = false;
                this.updated = false;

                /**
                 * Send request to API.
                 */
                var vm = this;
                const path = '/' + this.$root.app.client_id + '/user/verify-email';
                const url = this.$root.app.api_url + path;

                axios({
                    method: 'post',
                    url: url,
                    data: {
                        code: this.$route.params.code,
                        device: this.$root.getDevice(),
                        app_info: {
                            app_url: this.$root.app.app_url
                        }
                    }
                })
                .then(response => {
                    if (response.data.user) {
                        // Auto-login
                        vm.$root.user = response.data.user;
                        vm.$root.token = response.data.token;
                        vm.$root.resetSystemVariables();

                        // Set current language.
                        if (vm.$root.languages.length > 0) {
                            let currentLanguage = response.data.user.language_id;
                            if (!currentLanguage) {
                                currentLanguage = vm.$root.current_language;
                            }
                            if (currentLanguage) {
                                if (
                                    (vm.$root.current_language != currentLanguage)
                                    || (response.data.user.language_id != currentLanguage)
                                ) {
                                    let found = false;
                                    vm.$root.languages.forEach((item) => {
                                        if (item.active && (item.id == currentLanguage)) {
                                            found = true;
                                        }
                                    });

                                    if (found) {
                                        vm.$root.setLanguage(
                                            currentLanguage,
                                            currentLanguage != response.data.user.language_id
                                        );
                                    }
                                }
                            }
                        }
                    
                        // Create token cookie.
                        vm.$root.setCookie(vm.$root.app.client_id + '_api_token', response.data.token, '1Y');
                        vm.$root.getSelectedAssetsAndCollectionFromCookie();
                        vm.$root.getAssetsInfo();

                        // Show mega menu info modal?
                        vm.$root.showMegaMenuInfo();
                        
                        vm.$router.push({ name: vm.$root.isProofsGuest() ? 'proofs' : 'home' });

                        return null;
                    }
                    if (response.data.message == 'PreApproved') {
                        vm.updated = vm.$root.text(
                            'Thank you. Your email has been verified. Click on the link below to login.',
                            null,
                            true
                        );

                        return null;
                    }
                    vm.updated = vm.$root.text(
                        'Thank you. Your application has been received and is being processed.'
                            + ' You will shortly receive an email from the administrator.',
                        null, true
                    );
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true)
                })
                .finally(() =>
                    vm.loading = false
                );
            }
        }
    }
</script>
