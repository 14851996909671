<template>
    <div :class="(is_modal ? 'modal-content' : 'share-options-container pr-3')">
        <div v-if="is_modal" class="modal-header">
            <h5 class="modal-title" id="print-order-modal-label">
                <span v-if="sent">
                    {{ $root.text('Your order has been placed', null, true) }}
                </span>
                <span v-else>{{ $root.text('Order item', null, true) }}</span>
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <p v-else>
            <span style="text-transform: uppercase">{{ $root.text('ORDER ITEM') }}</span>
            <span v-if="asset" class="float-right">
                {{ $root.text(
                    '{resource}:',
                    { resource: $root.text($root.contents.asset_title, null, false, 'asset_title') },
                    true
                ) }}
                {{ asset.id }}
            </span>
        </p>

        <div :class="(is_modal ? 'modal-body' : '')" style="min-height: 300px">
            <loading v-if="loading"></loading>

            <div v-if="loading_errors" v-html="loading_errors" class="alert alert-danger" role="alert"></div>

            <div v-if="(!loading && !loading_errors)">
                <div v-if="(price_panels.length > 1) && (step == 1)" class="form-group">
                    <label for="print-order-price-panel" v-if="$root.contents.select_price_panel_text">
                        {{ $root.contents.select_price_panel_text }}
                    </label>
                    <searchable-option-list
                        v-model="price_panel_id"
                        :value="price_panel_id"
                        :options="price_panels"
                        field_id="print-order-price-panel"
                        :search_placeholder="$root.text('Select option', null, true)"
                    ></searchable-option-list>
                </div>
                <div v-if="price_panel">
                    <div v-if="sent">
                        <div
                            v-if="$root.contents.print_order_confirmation"
                            v-html="$root.contents.print_order_confirmation"
                        ></div>
                        <table v-else class="mb-3">
                            <tbody>
                                <tr>
                                    <td>{{ $root.text('Order No.', null, true) }}&nbsp;&nbsp;</td>
                                    <td>{{ print_order_id }}</td>
                                </tr>
                                <tr>
                                    <td>{{ $root.text('Item', null, true) }}&nbsp;&nbsp;</td>
                                    <td>{{ asset.title }}</td>
                                </tr>
                                <tr>
                                    <td>{{ $root.text('Quantity', null, true) }}&nbsp;&nbsp;</td>
                                    <td>{{ quantity }}</td>
                                </tr>
                                <tr>
                                    <td>{{ $root.text('Price', null, true) }}&nbsp;&nbsp;</td>
                                    <td>
                                        <span v-if="(getCurrency() == 'pound')">
                                            &pound;{{ total_price.toFixed(2) }}
                                        </span>
                                        <span v-if="(getCurrency() == 'euro')">
                                            &euro;{{ total_price.toFixed(2) }}
                                        </span>
                                        <span v-if="(getCurrency() == 'dollar')">
                                            &dollar;{{ total_price.toFixed(2) }}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            {{
                                $root.text(
                                    'You can check the status of all orders in {my_account}',
                                    { my_account : $root.text('My account', null, true) },
                                    true
                                )
                            }}
                            <br><br>
                            <a href="#" data-dismiss="modal" @click.prevent="finish('my-account')">
                                {{ $root.text('My account', null, true) }}
                            </a>
                        </p>
                        <!--<p v-if="$root.editing_artwork" class="mb-0" style="font-weight: 500">
                            What would you like to do now?
                        </p>-->
                    </div>
                    <div v-else>
                        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                        <div class="row">
                            <div class="col-lg-6 col-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text bg-dark text-white" style="border: none">
                                                {{ $root.text('Quantity', null, true) }}
                                            </span>
                                        </div>
                                        <input
                                            v-model="quantity"
                                            type="number"
                                            class="form-control"
                                            id="print-order-quantity"
                                            :min="(price_panel.min_order || 1)"
                                            :max="(price_panel.max_order || null)"
                                            :readonly="sending"
                                        >
                                        <div
                                            v-if="((step > 1) || total_price)"
                                            class="input-group-append"
                                            :key="'total-cost'"
                                        >
                                            <span class="input-group-text bg-dark text-white" style="border: none">
                                                <span class="mr-1">{{ $root.text('Total cost:', null, true) }}</span>
                                                <span v-if="(getCurrency() == 'pound')">
                                                    &pound;{{ total_price.toFixed(2) }}
                                                </span>
                                                <span v-if="(getCurrency() == 'euro')">
                                                    &euro;{{ total_price.toFixed(2) }}
                                                </span>
                                                <span v-if="(getCurrency() == 'dollar')">
                                                    &dollar;{{ total_price.toFixed(2) }}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-12"></div>
                        </div>
                        <div id="print-order-step-1">
                            <p v-if="(price_panel.min_order || price_panel.max_order)">
                                <span v-if="price_panel.min_order" class="mr-3">
                                    {{ $root.text('Minimum quantity: {x}', { x: price_panel.min_order }, true) }}
                                </span>
                                <span v-if="price_panel.max_order">
                                    {{ $root.text('Maximum quantity: {x}', { x: price_panel.max_order }, true) }}
                                </span>
                            </p>
                            <div class="table-responsive mb-3">
                                <table class="table table-centered table-sm">
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col" class="text-center">
                                                {{ $root.text('Quantity', null, true) }}
                                            </th>
                                            <th scope="col" class="text-center">
                                                <span v-if="(getCurrency() == 'pound')">&pound;</span>
                                                <span v-if="(getCurrency() == 'euro')">&euro;</span>
                                                <span v-if="(getCurrency() == 'dollar')">&dollar;</span>
                                            </th>
                                            <th scope="col" class="text-center">
                                                <span v-if="(getCurrency() == 'pound')">&pound;</span>
                                                <span v-if="(getCurrency() == 'euro')">&euro;</span>
                                                <span v-if="(getCurrency() == 'dollar')">&dollar;</span>
                                                P&P
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in getPrices()" :key="item.id">
                                            <td scope="row" class="text-center">
                                                <a href="#" @click.prevent="quantity = item.quantity">
                                                    {{ item.quantity }}
                                                </a>
                                            </td>
                                            <td scope="row" class="text-center">
                                                {{ item.price ? item.price.toFixed(2) : '0.00' }}
                                            </td>
                                            <td scope="row" class="text-center">
                                                {{ item.price_pnp ? item.price_pnp.toFixed(2) : '0.00' }}
                                            </td>
                                        </tr>
                                        <tr v-if="hasIncrements()">
                                            <td scope="row" colspan="3" class="bg-light pl-2">
                                                {{ $root.text('Increments (above max pack size)', null, true)  }}
                                            </td>
                                        </tr>
                                        <tr v-if="hasIncrements()">
                                            <td scope="row" class="text-center">{{ price_panel.inc_quantity }}</td>
                                            <td scope="row" class="text-center">
                                                {{ (price_panel['inc_' + getCurrency() + 's'] !== null)
                                                    ? parseFloat(price_panel['inc_' + getCurrency() + 's']).toFixed(2)
                                                    : '0.00' }}
                                            </td>
                                            <td scope="row" class="text-center">
                                                {{ (price_panel['inc_' + getCurrency() + 's_pnp'] !== null)
                                                    ? parseFloat(price_panel['inc_' + getCurrency() + 's_pnp']).toFixed(2)
                                                    : '0.00' }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p v-if="price_panel.notes_html" v-html="price_panel.notes_html"></p>
                            <p class="small">
                                <strong>{{ $root.text('Note:', null, true) }}</strong>
                                {{ $root.text('Quantities not matching a pack size or increment will be rounded up or '
                                    + 'down to the nearest quantity.', null, true) }}
                            </p>
                        </div>
                        <div id="print-order-step-2">
                            <form v-on:submit.prevent="onSubmit()">
                                <div class="row">
                                    <div class="col-lg-6 col-12">
                                        <div class="form-group">
                                            <label
                                                for="print-order-delivery_address"
                                                id="print-order-delivery_address-label"
                                                class="mandatory-label"
                                            >{{ $root.text('Delivery address', null, true) }} *</label>
                                            <textarea
                                                v-model="delivery_address"
                                                id="print-order-delivery_address"
                                                class="form-control mandatory-field"
                                                rows="5"
                                                :readonly="sending"
                                                v-on:keyup="$root.unHighlightErrors('print-order-delivery_address')"
                                                v-on:change="$root.unHighlightErrors('print-order-delivery_address')"
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-12">
                                        <div class="form-group">
                                            <label for="print-order-comments">
                                                {{ $root.text('Additional instructions', null, true) }}
                                            </label>
                                            <textarea
                                                v-model="comments"
                                                id="print-order-comments"
                                                class="form-control"
                                                rows="5"
                                                :readonly="sending"
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div v-if="$root.contents.print_order_custom_field_1_title" class="col-lg-6 col-12">
                                        <div class="form-group">
                                            <label
                                                for="print-order-custom_field_1"
                                                id="print-order-custom_field_1-label"
                                                class="mandatory-label"
                                            >
                                                {{ $root.contents.print_order_custom_field_1_title }}
                                                <span v-if="$root.client.print_order_custom_field_1_required">*</span>
                                            </label>
                                            <input
                                                v-model="custom_field[1]"
                                                type="text"
                                                id="print-order-custom_field_1"
                                                class="form-control mandatory-field"
                                                :readonly="sending"
                                                v-on:keyup="$root.unHighlightErrors('print-order-custom_field_1')"
                                                v-on:change="$root.unHighlightErrors('print-order-custom_field_1')"
                                            >
                                        </div>
                                    </div>
                                    <div v-if="$root.contents.print_order_custom_field_2_title" class="col-lg-6 col-12">
                                        <div class="form-group">
                                            <label
                                                for="print-order-custom_field_2"
                                                id="print-order-custom_field_2-label"
                                                class="mandatory-label"
                                            >
                                                {{ $root.contents.print_order_custom_field_2_title }}
                                                <span v-if="$root.client.print_order_custom_field_2_required">*</span>
                                            </label>
                                            <input
                                                v-model="custom_field[2]"
                                                type="text"
                                                id="print-order-custom_field_2"
                                                class="form-control mandatory-field"
                                                :readonly="sending"
                                                v-on:keyup="$root.unHighlightErrors('print-order-custom_field_2')"
                                                v-on:change="$root.unHighlightErrors('print-order-custom_field_2')"
                                            >
                                        </div>
                                    </div>
                                    <div v-if="$root.contents.print_order_custom_field_3_title" class="col-lg-6 col-12">
                                        <div class="form-group">
                                            <label
                                                for="print-order-custom_field_3"
                                                id="print-order-custom_field_3-label"
                                                class="mandatory-label"
                                            >
                                                {{ $root.contents.print_order_custom_field_3_title }}
                                                <span v-if="$root.client.print_order_custom_field_3_required">*</span>
                                            </label>
                                            <input
                                                v-model="custom_field[3]"
                                                type="text"
                                                id="print-order-custom_field_3"
                                                class="form-control mandatory-field"
                                                :readonly="sending"
                                                v-on:keyup="$root.unHighlightErrors('print-order-custom_field_3')"
                                                v-on:change="$root.unHighlightErrors('print-order-custom_field_3')"
                                            >
                                        </div>
                                    </div>
                                    <div v-if="$root.contents.print_order_custom_field_4_title" class="col-lg-6 col-12">
                                        <div class="form-group">
                                            <label
                                                for="print-order-custom_field_4"
                                                id="print-order-custom_field_4-label"
                                                class="mandatory-label"
                                            >
                                                {{ $root.contents.print_order_custom_field_4_title }}
                                                <span v-if="$root.client.print_order_custom_field_4_required">*</span>
                                            </label>
                                            <input
                                                v-model="custom_field[4]"
                                                type="text"
                                                id="print-order-custom_field_4"
                                                class="form-control mandatory-field"
                                                :readonly="sending"
                                                v-on:keyup="$root.unHighlightErrors('print-order-custom_field_4')"
                                                v-on:change="$root.unHighlightErrors('print-order-custom_field_4')"
                                            >
                                        </div>
                                    </div>
                                    <div v-if="asset.template" class="col pt-4">
                                        <div class="custom-control custom-checkbox">
                                            <input
                                                v-model="artwork_checked"
                                                type="checkbox"
                                                class="custom-control-input"
                                                id="print-order-artwork_checked"
                                                :disabled="sending"
                                            >
                                            <label
                                                class="custom-control-label mandatory-label"
                                                for="print-order-artwork_checked"
                                                id="print-order-artwork_checked-label"
                                            >
                                                <span v-if="asset.template">
                                                    {{ $root.text('Tick to confirm you have checked your version.', null,
                                                        true) }}
                                                </span>
                                                <span v-else>
                                                    {{ $root.text(
                                                        'Tick to confirm you have checked your {resource}.',
                                                        {
                                                            resource: $root.text(
                                                                $root.contents.asset_title,
                                                                null,
                                                                false,
                                                                'asset_title'
                                                            )
                                                        },
                                                        true
                                                    ) }}
                                                </span>
                                                <a href="#" @click.prevent="downloadFile()">
                                                    {{ $root.text('Click here', null, true) }}
                                                </a>
                                                {{ $root.text('{click_here} to download the file.',
                                                    { click_here: '' }) }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <hr>
                            <div class="row">
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>{{ $root.text('Print specification', null, true) }}</label>
                                        <div
                                            class="form-control bg-light"
                                            style="height: auto"
                                            v-html="price_panel.specification_html"
                                        ></div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group">
                                        <label>{{ $root.text('Supplier', null, true) }}</label>
                                        <div class="form-control bg-light" style="height: auto">
                                            {{ price_panel['supplier_' + getCurrency() + 's_name'] }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!loading_errors && price_panel" :class="(is_modal ? 'modal-footer' : '')">
            <span v-if="sent" class="sent-buttons">
                <button
                    :key="'finish-button'"
                    type="button"
                    class="btn btn-light"
                    data-dismiss="modal"
                    :disabled="opening_artwork_editor"
                    @click="finish()"
                >
                    <i class="far fa-times mr-1"></i> {{ $root.text('Close', null, true) }}
                </button>
            </span>
            <span v-else>
                <span v-if="(step == 1)">
                    <button
                        :key="'confirm-button'"
                        type="button"
                        class="btn btn-primary"
                        @click="nextStep()"
                        :disabled="!quantity"
                    >{{ $root.text('Confirm', null, true) }}</button>
                </span>
                <span v-else>
                    <span v-if="!sending">
                        <button
                            :key="'place-order-button'"
                            type="button"
                            class="btn btn-primary"
                            @click="onSubmit()"
                            :disabled="(step < 2)"
                        >
                            <i class="far fa-shopping-cart mr-1"></i> {{ $root.text('Place order', null, true) }}
                        </button>
                    </span>
                    <span v-else>
                        <button :key="'ordering-button'" type="button" class="btn btn-primary">
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">{{ $root.text('Ordering', null, true) }}</span>
                        </button>
                    </span>
                </span>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['is_modal'],
        data () {
            return {
                errors: false,
                loading_errors: false,
                loading: true,
                asset: null,
                template: null,
                artwork: null,
                sending: false,
                sent: false,
                price_panel: null,
                quantity: null,
                total_price: 0,
                step: 1,
                delivery_address: null,
                comments: null,
                custom_field: {},
                get_content_response: null,
                artwork_checked: false,
                downloading: false,
                opening_artwork_editor: false,
                duplicating_artwork: false,
                reorder: null,
                print_order_id: null,
                price_panels: [],
                price_panel_id: null
            }
        },
        mounted () {
            //
        },
        watch: {
            quantity: function () {
                this.step = 1;
                $('#print-order-quantity').removeClass('field-error');
                this.errors = false;
                this.updateTotal();
            },
            step: function (val, oldVal) {
                $('#print-order-step-' + oldVal).fadeOut(350, function () {
                    $('#print-order-step-' + val).fadeIn(350);
                });
            },
            get_content_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        this.$root.contents[val.key] = val.content;
                    }
                }
            },
            artwork_checked: function (val) {
                if (val) {
                    $('#print-order-artwork_checked-label').removeClass('text-danger');
                }
            },
            sent: function (val) {
                if (!this.is_modal) {
                    return null;
                }
                if (val) {
                    $('#print-order-modal > div').removeClass('modal-lg');

                    return null;
                }
                $('#print-order-modal > div').addClass('modal-lg');
            },
            price_panel_id: function (val) {
                let pricePanel = null;
                this.price_panels.forEach((item) => {
                    if (item.id == val) {
                        pricePanel = item;
                    }
                });
                this.price_panel = pricePanel;
                this.quantity = null;
                this.total_price = 0;
            }
        },
        methods: {
            resetForm: function (asset, template = null, artwork = null, reorder = null) {
                this.errors = false;
                this.loading_errors = false;
                this.loading = true;
                this.asset = asset;
                this.template = template;
                this.artwork = artwork;
                this.sent = false;
                this.price_panel = null;
                this.price_panels = [];
                this.price_panel_id = null;
                this.quantity = null;
                this.total_price = 0;
                this.step = 1;
                this.delivery_address = this.$root.user.first_name + ' ' + this.$root.user.last_name + '\n'
                    + this.$root.user.company;
                this.comments = null;
                this.custom_field = {};
                this.artwork_checked = !asset.template;
                this.reorder = reorder;
                this.print_order_id = null;

                if (!this.canOrderPrint()) {
                    if (!this.loading_errors) {
                        this.loading_errors = this.$root.text(
                            'This {resource} is not available to order.',
                            { resource: this.$root.text(this.$root.contents.asset_title, null, false, 'asset_title') },
                            true
                        );
                    }
                    this.loading = false;

                    return null;
                }

                if (reorder) {
                    this.quantity = reorder.quantity;
                    this.delivery_address = reorder.delivery_address;
                    this.comments = reorder.comments;
                    if (reorder.custom_field_1) {
                        this.custom_field[1] = reorder.custom_field_1;
                    }
                    if (reorder.custom_field_2) {
                        this.custom_field[2] = reorder.custom_field_2;
                    }
                    if (reorder.custom_field_3) {
                        this.custom_field[3] = reorder.custom_field_3;
                    }
                    if (reorder.custom_field_4) {
                        this.custom_field[4] = reorder.custom_field_4;
                    }
                }
                
                // Get delivery address from profile, if available.
                if (this.$root.isFeatured('profiles') && !reorder) {
                    let profileId = this.$root.user.profile_id;
                    if (artwork) {
                        if (artwork.profile_id) {
                            profileId = artwork.profile_id;
                        }
                    }

                    if (!profileId) {
                        this.loading_errors = this.$root.text('You do not have a profile.', null, true);

                        return null;
                    }
                    this.getDeliveryAddress(profileId);
                }

                asset.price_panels.forEach((pricePanelId) => {
                    this.getPricePanel(pricePanelId);
                });
                this.$root.getContent(this, 'print_order_custom_field_1_title');
                this.$root.getContent(this, 'print_order_custom_field_2_title');
                this.$root.getContent(this, 'print_order_custom_field_3_title');
                this.$root.getContent(this, 'print_order_custom_field_4_title');
                this.$root.getContent(this, 'print_order_confirmation');
                this.$root.getContent(this, 'select_price_panel_text');
            },
            canOrderPrint: function () {
                if (!this.$root.isFeatured('printing')) {
                    this.loading_errors = this.$root.text('Item ordering is not available.', null, true);

                    return false;
                }
                if (!this.$root.user.currency) {
                    this.loading_errors = this.$root.text('You do not have a currency.', null, true);

                    return false;
                }
                if (!this.asset) {
                    return false;
                }
                if (this.asset.price_panels.length == 0) {
                    this.loading_errors = this.$root.text(
                        'A price panel has not been assigned to this item. Please contact your administrator using the '
                            + '{support} link at the top of this screen.',
                        { support: this.$root.text('support', null, true) },
                        true
                    );

                    return false;
                }
                if (this.reorder) {
                    if (!this.reorder.file) {
                        this.loading_errors = this.$root.text('Artwork file is missing.', null, true);

                        return false;
                    }
                }
                if (!this.asset.template) {
                    if (!this.asset.print_order) {
                        return false;
                    }
                    /*if (!this.reorder && !this.asset.file) {
                        this.loading_errors = 'Artwork file is missing.';

                        return false;
                    }*/

                    return true;
                }
                if (!this.template || (!this.artwork && !this.reorder)) {
                    return false;
                }
                if (!this.reorder) {
                    if (!this.artwork.pdf) {
                        this.loading_errors = this$root.text('Artwork file is missing.', null, true);

                        return false;
                    }
                }

                return this.template.output_order;
            },
            getDeliveryAddress: function (profileId) {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile/' + profileId;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    response.data.field_contents.forEach((item) => {
                        if ((item.title == 'Delivery address') && (item.title_suffix == '_L')) {
                            vm.delivery_address = item.content;
                        }
                    });
                })
                .catch(function (error) {
                    vm.loading_errors = vm.$root.text('Profile not found.', null, true);
                });
            },
            getPricePanel: function (pricePanelId) {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/price-panel/' + pricePanelId;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    let pricePanel = response.data.price_panel;
                    pricePanel.name = pricePanel.title;
                    if (!pricePanel.specification_html) {
                        pricePanel.specification_html = '&nbsp;';
                    }
                    if (!pricePanel.items) {
                        vm.loading_errors = vm.$root.text('No prices available.', null, true);
                        vm.loading = false;

                        return null;
                    }
                    if (!vm.getCurrency(pricePanel)) {
                        vm.loading_errors = vm.$root.text(
                            'The price panel has been incorrectly set for this item. Please contact your administrator '
                                + 'using the {support} link at the top of this screen.',
                            { support: vm.$root.text('support', null, true) },
                            true
                        );
                        vm.loading = false;
                        
                        return null;
                    }
                    vm.price_panels.push(pricePanel);
                    if (vm.price_panels.length < vm.asset.price_panels.length) {
                        return null;
                    }
                    // Order by title.
                    for (let i = 0; i < vm.price_panels.length - 1; i++) {
                        for (let j = i + 1; j < vm.price_panels.length; j++) {
                            if (vm.price_panels[j].title < vm.price_panels[i].title) {
                                let temp = vm.price_panels[i];
                                vm.price_panels[i] = vm.price_panels[j];
                                vm.price_panels[j] = temp;
                            }
                        }
                    }

                    if (vm.price_panels.length == 1) {
                        vm.price_panel_id = vm.price_panels[0].id;
                    }
                    setTimeout(function () {
                        vm.loading = false;
                    },200);
                })
                .catch(function (error) {
                    vm.loading = false;
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#print-order-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.status == 404) {
                        vm.loading_errors = vm.$root.text(
                            'A price panel has not been assigned to this item. Please contact your administrator '
                                + 'using the {support} link at the top of this screen.',
                            { support: vm.$root.text('support', null, true) },
                            true
                        );

                        return null;
                    }
                    vm.loading_errors = error.response.data.error
                        || $root.text('Something went wrong, please try again', null, true);
                });
            },
            getCurrency: function (pricePanel = null) {
                if (!pricePanel) {
                    pricePanel = this.price_panel;
                }
                if (!pricePanel) {
                    return null;
                }
                let poundsOk = false;
                let eurosOk = false;
                let dollarsOk = false;
                let currency = null;
                pricePanel.items.forEach((item) => {
                    if (item.quantity) {
                        if (item['price_pounds'] !== null) {
                            poundsOk = true;
                        }
                        if (item['price_euros'] !== null) {
                            eurosOk = true;
                        }
                        if (item['price_dollars'] !== null) {
                            dollarsOk = true;
                        }
                    }
                });
                if (poundsOk && pricePanel['supplier_pounds_name']) {
                    currency = 'pound';
                }
                if (
                    (!currency || this.$root.user.currency != currency)
                    && eurosOk
                    && pricePanel['supplier_euros_name']
                ) {
                    currency = 'euro';
                }
                if (
                    (!currency || this.$root.user.currency != currency)
                    && dollarsOk
                    && pricePanel['supplier_dollars_name']
                ) {
                    currency = 'dollar';
                }

                return currency;
            },
            getPrices: function () {
                let prices = [];
                this.price_panel.items.forEach((item) => {
                    if (item.quantity && (item['price_' + this.getCurrency() + 's'] !== null)) {
                        prices.push({
                            id: item.id,
                            quantity: item.quantity,
                            price: parseFloat(item['price_' + this.getCurrency() + 's']),
                            price_pnp: parseFloat(item['price_' + this.getCurrency() + 's_pnp'])
                        });
                    }
                });
                if (prices.length == 0) {
                    return null;
                }

                return prices;
            },
            hasIncrements: function () {
                return (this.price_panel.inc_quantity && (this.price_panel['inc_' + this.getCurrency() + 's'] !== null));
            },
            nextStep: function () {
                if (!this.updateTotal(true)) {
                    return null;
                }
                var vm = this;
                setTimeout(function () {
                    vm.step = 2;
                }, 200);
            },
            updateTotal: function (checkQuantity = false) {
                let quantity = parseInt(this.quantity);
                if (isNaN(quantity)) {
                    quantity = 0;
                }
                if (quantity < 0) {
                    quantity = 0;
                }
                if (!quantity) {
                    if (!checkQuantity) {
                        this.total_price = 0;

                        return null;
                    }
                    this.errors = this.$root.text('Please enter a quantity.', null, true);
                    $('#print-order-quantity').addClass('field-error');

                    return null;
                }
                if (this.price_panel.min_order) {
                    if (quantity < this.price_panel.min_order) {
                        if (checkQuantity) {
                            this.errors = this.$root.text(
                                'The quantity ordered must be above the minimum quantity.',
                                null,
                                true
                            );
                            $('#print-order-quantity').addClass('field-error');
                        }

                        return null;
                    }
                }
                if (this.price_panel.max_order) {
                    if (quantity > this.price_panel.max_order) {
                        if (checkQuantity) {
                            this.errors = this.$root.text(
                                'The quantity ordered must be below the maximum quantity.',
                                null,
                                true
                            );
                            $('#print-order-quantity').addClass('field-error');
                        }

                        return null;
                    }
                }
                const minQuantity = this.price_panel.min_order || 1;
                const maxQuantity = this.price_panel.max_order || 99999;
                let quantityFound = false;
                let totalPrice = 0;
                let lastQuantity = 0;
                let lastPrice = 0;
                const prices = this.getPrices();
                prices.forEach((price) => {
                    if (!quantityFound) {
                        if ((price.quantity >= minQuantity) && (price.quantity <= maxQuantity)) {
                            let total = price.price;
                            if (price.price_pnp) {
                                total += price.price_pnp;
                            }
                            if (quantity == price.quantity) {
                                quantityFound = true;
                                totalPrice = total;
                            } else if (quantity < price.quantity) {
                                quantity = price.quantity;
                                quantityFound = true;
                                totalPrice = total;
                            } else {
                                lastQuantity = price.quantity;
                                lastPrice = total;
                            }
                        }
                    }
                });

                if (!quantityFound && this.hasIncrements()) {
                    if ((lastQuantity + this.price_panel.inc_quantity) <= maxQuantity) {
                        let increment = 0;
                        do {
                            increment++;
                            lastQuantity += this.price_panel.inc_quantity;
                            if (
                                (quantity <= lastQuantity)
                                || ((lastQuantity + this.price_panel.inc_quantity) > maxQuantity)
                            ) {
                                quantityFound = true;
                                quantity = lastQuantity;
                            }
                        } while (!quantityFound);
                        let incrementsTotal =
                            (increment * parseFloat(this.price_panel['inc_' + this.getCurrency() + 's']));
                        if (this.price_panel['inc_' + this.getCurrency() + 's_pnp']) {
                            incrementsTotal +=
                                (increment * parseFloat(this.price_panel['inc_' + this.getCurrency() + 's_pnp']));
                        }
                        totalPrice = lastPrice + incrementsTotal;
                    }
                }

                if (!quantityFound && lastQuantity) {
                    quantity = lastQuantity;
                    totalPrice = lastPrice;
                    quantityFound = true;
                }
                if (!quantityFound) {
                    if (checkQuantity) {
                        this.errors = 'Invalid quantity.';
                        $('#print-order-quantity').addClass('field-error');
                    }

                    return null;
                }
                this.total_price = totalPrice;
                if (!checkQuantity) {
                    return null;
                }
                if (parseInt(this.quantity) != quantity) {
                    this.quantity = quantity;
                }

                return true;
            },
            downloadFile: function () {
                if (this.downloading) {
                    return null;
                }
                this.downloading = true;
                const download = this.reorder ? this.reorder.file
                    : (this.asset.template ? this.artwork.pdf : this.asset.file);
                /**
                 * Send request to API
                 */
                let data = {
                    file_path: download.file_path,
                    file_type: download.file_type,
                    download_filename: 'artwork.' + download.extension
                };
                if (this.reorder) {
                    data.item_type = 'print_order_file';
                    data.item_id = this.reorder.id;
                } else if (this.asset.template) {
                    data.item_type = 'artwork_pdf';
                    data.item_id = this.artwork.id;
                } else {
                    data.item_type = 'resource_print_order';
                    data.item_id = this.asset.id;
                }
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/request-download-token';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    const url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/download/'
                        + response.data.token;
                    window.location.href = url;
                })
                .catch(function (error) {
                    // Show error?
                    if (error.response.data.error) {
                        alert(error.response.data.error);
                    }
                })
                .finally(() =>
                    vm.downloading = false
                );
            },
            onSubmit: function () {
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                if (!this.artwork_checked) {
                    this.errors = this.asset.template
                        ? this.$root.text('You must confirm you have checked your version.', null, true)
                        : this.$root.text(
                            'You must confirm you have checked your {resource}.',
                            { resource: this.$root.text(this.$root.contents.asset_title, null, false, 'asset_title') },
                            true
                        );
                    $('#print-order-artwork_checked-label').addClass('text-danger');

                    return null;
                }
                this.errors = false;
                this.sending = true;

                /**
                 * Send request to API.
                 */
                let data = {
                    asset_id: this.asset.id,
                    currency: this.getCurrency(),
                    quantity: this.quantity,
                    total_price: this.total_price,
                    delivery_address: this.delivery_address,
                    comments: this.comments,
                    custom_fields: this.custom_field,
                    price_panel_id: this.price_panel.id,
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                };
                if (this.reorder) {
                    data.reorder = this.reorder.id;
                } else {
                    data.artwork_id = this.asset.template ? this.artwork.id : null;
                }
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/print-order';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // If artwork requires approval and not approved show the approval request form.
                    if (response.data.message == 'ArtworkApprovalRequired') {
                        vm.resetArtworkApprovalRequestForm();

                        return null;
                    }

                    vm.sent = 'Success';
                    vm.print_order_id = response.data.print_order_id;
                    if ((vm.$route.name == 'print-orders') || (vm.$route.name == 'my-account-print-orders')) {
                        const component = vm.$root.findComponent(vm.$parent, 'print-orders');
                        if (component) {
                            component.getOrders();
                        }
                    }
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            $('#print-order-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    vm.errors += value.join('<br>') + '<br>';
                                    $('#print-order-' + key + '-label').addClass('text-danger');
                                    $('#print-order-' + key).addClass('field-error');
                                }
                            }
                            $('#print-order-modal .modal-body').animate({ scrollTop: 0 }, 350);
                        }
                    }
                })
                .finally(() =>
                    vm.sending = false
                );
            },
            finish: function (action = null, delay = true) {
                if (delay) {
                    var vm = this;
                    setTimeout(function () {
                        vm.finish(action, false);
                    }, 200);

                    return null;
                }
                if (this.$root.editing_artwork) {
                    this.$parent.hideArtworkEditor();
                }
                if (!action) {
                    return null;
                }

                if (this.$route.name == action) {
                    return null;
                }
                this.$router.push({ name: action });
            },
            createArtwork: function () {
                this.opening_artwork_editor = true;
                this.duplicating_artwork = false;
                /**
                 * Send get template details request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/' + this.asset.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.template.fields = response.data.template.fields;
                    vm.showArtworkEditor();
                })
                .catch(function (error) {
                    //
                })
                .finally(() => {
                    vm.opening_artwork_editor = false;
                });
            },
            duplicateArtwork: function () {
                this.opening_artwork_editor = true;
                this.duplicating_artwork = true;

                /**
                 * Send get artwork request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/'
                    + this.template.id + '/artwork/' + this.artwork.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.showArtworkEditor(response.data.artwork, true);
                })
                .catch(function (error) {
                    //
                })
                .finally(() => {
                    vm.opening_artwork_editor = false;
                });
            },
            showArtworkEditor: function (artwork = null, duplicate = false) {
                $('#print-order-modal').modal('hide');
                this.$parent.showArtworkEditor(
                    this.asset,
                    this.template,
                    artwork,
                    duplicate
                );
            },
            resetArtworkApprovalRequestForm: function () {
                const component = this.$root.findComponent(this.$parent, 'artwork-approval-request-form');
                if (component) {
                    $('#print-order-modal').modal('hide');
                    component.resetForm();
                    setTimeout(function () {
                        $('#artwork-approval-request-modal').modal('show');
                    }, 500);
                }
            }
        }
    }
</script>
<style scoped>
    #print-order-form-modal > div {
        transition: max-width .35s ease;
    }

    #print-order-step-2 {
        display: none;
    }

    .sent-buttons .left {
        position: absolute;
        left: 15px;
    }

    @media (max-width: 575px) {
        .sent-buttons {
            width: 100%;
        }

        .sent-buttons .left {
            position: relative;
            left: 0;
            display: block;
            width: 100%;
        }

        .sent-buttons .btn-xs-block {
            width: 100%;
        }
    }
</style>
