<template>
    <div>
        <loading v-if="loading"></loading>

        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="profile_group_loaded">
            <div class="card">
                <div class="card-body pb-3">
                    <h4>{{ profile_group.name }}</h4>
                    <div class="row">
                        <div class="col-lg-4 col-sm-6 col-12 pb-2">
                            {{ $root.text('Primary user:', null, true) }}'
                            <span v-if="profile_group.primary_contact">
                                {{ profile_group.primary_contact_name }}
                            </span>
                            <span v-else>{{ $root.text('(You will select this when users are added)') }}</span>
                        </div>
                        <div v-if="profile_group.primary_contact_email" class="col-lg-4 col-sm-6 col-12 pb-2">
                            {{ $root.text('Email:', null, true) }}
                            <a
                                v-if="profile_group.primary_contact_email"
                                :href="'mailto:' + profile_group.primary_contact_email"
                            >
                                {{ profile_group.primary_contact_email }}
                            </a>
                        </div>
                        <div v-if="profile_group.primary_contact_phone" class="col-lg-4 col-sm-6 col-12 pb-2">
                            {{ $root.text('Telephone:', null, true) }} {{ profile_group.primary_contact_phone }}
                        </div>
                        <div v-if="$root.client.show_profile_group_description" class="col-lg-4 col-sm-6 col-12 pb-2">
                            {{ $root.text(
                                '{profile_group_description}:',
                                {
                                    profile_group_description: $root.text(
                                        $root.contents.profile_group_description_title,
                                        null,
                                        false,
                                        'profile_group_description_title'
                                    )
                                },
                                null,
                                true
                            ) }}
                            {{ profile_group.description }}
                        </div>
                        <div v-if="$root.client.show_profile_group_subfolder_id" class="col-lg-4 col-sm-6 col-12 pb-2">
                            {{ $root.text(
                                '{folder}:',
                                { folder: $root.text($root.contents.folder_title, null, false, 'folder_title') },
                                true
                            ) }}
                            <span v-if="profile_group.folder_name">{{ profile_group.folder_name }}</span>
                            <span v-else>{{ $root.text('None selected', null, true) }}</span>
                        </div>
                        <div v-if="$root.client.show_profile_group_subfolder_id" class="col-lg-4 col-sm-6 col-12 pb-2">
                            {{ $root.text(
                                '{subfolder}:',
                                {
                                    subfolder: $root.text(
                                        $root.contents.subfolder_title,
                                        null,
                                        false,
                                        'subfolder_title'
                                    )
                                },
                                true
                            ) }}
                            <span v-if="profile_group.subfolder_name">{{ profile_group.subfolder_name }}</span>
                            <span v-else>{{ $root.text('None selected', null, true) }}</span>
                        </div>
                        <div
                            v-if="$root.client.print_ordering_approval && profile_group.print_approver_name"
                            class="col-lg-4 col-sm-6 col-12 pb-2"
                        >
                            {{ $root.text(
                                '{financial_approver}:',
                                {
                                    financial_approver: $root.text(
                                        $root.contents.financial_approver_title,
                                        null,
                                        false,
                                        'financial_approver_title'
                                    )
                                },
                                true
                            ) }}
                            {{ profile_group.print_approver_name }}
                        </div>
                        <div v-if="$root.client.show_profile_group_account_number" class="col-lg-4 col-sm-6 col-12 pb-2">
                            {{ $root.text('Account number:', null, true) }} {{ profile_group.account_number }}
                        </div>
                        <div v-if="$root.client.show_profile_group_consultant" class="col-lg-4 col-sm-6 col-12 pb-2">
                            {{ $root.text('Consultant:', null, true) }} {{ profile_group.consultant_name }}
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12 pb-2">
                            {{ addedBy() }}
                        </div>
                        <div v-if="profile_group.updated_by_name" class="col-lg-4 col-sm-6 col-12 pb-2">
                            {{ $root.text('Edited by: {name}', { name: profile_group.updated_by_name }, true) }}
                            {{ $root.text('on {date}', { date: formatDate(profile_group.updated_at) }) }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div
                    v-if="$root.hasPermission('reports_overview') || $root.hasPermission('reports_users')"
                    class="col-sm-4 col-12"
                >
                    <div class="card">
                        <div class="card-body p-0">
                            <div v-if="stats.visits.loading" style="padding-top: 55px; padding-bottom: 54px">
                                <loading></loading>
                            </div>
                            <profile-group-stats-with-chart
                                v-else
                                :chart_height="80"
                                :chart_data="stats.visits.data"
                                :title="$root.text('Visits last 30 days', null, true)"
                                :total="stats.visits.total"
                                :increase_percent="stats.visits.increase"
                            ></profile-group-stats-with-chart>
                        </div>
                    </div>
                </div>
                <div
                    v-if="$root.hasPermission('reports_overview') || $root.hasPermission('reports_users')"
                    class="col-sm-4 col-12"
                >
                    <div class="card">
                        <div class="card-body p-0">
                            <div v-if="stats.registrations.loading" style="padding-top: 55px; padding-bottom: 54px">
                                <loading></loading>
                            </div>
                            <profile-group-stats-with-chart
                                v-else
                                :chart_height="80"
                                :chart_data="stats.registrations.data"
                                :title="$root.text('New users', null, true)"
                                :total="stats.registrations.total"
                                :increase_percent="stats.registrations.increase"
                            ></profile-group-stats-with-chart>
                        </div>
                    </div>
                </div>
                <div
                    v-if="$root.isFeatured('templates')
                        && ($root.hasPermission('reports_overview') || $root.hasPermission('reports_templates'))"
                    class="col-sm-4 col-12"
                >
                    <div class="card">
                        <div class="card-body p-0">
                            <div v-if="stats.templates.loading" style="padding-top: 55px; padding-bottom: 54px">
                                <loading></loading>
                            </div>
                            <profile-group-stats-with-chart
                                v-else
                                :chart_height="80"
                                :chart_data="stats.templates.data"
                                :title="$root.text('Orders and downloads', null, true)"
                                :total="stats.templates.total"
                                :increase_percent="stats.templates.increase"
                            ></profile-group-stats-with-chart>
                        </div>
                    </div>
                </div>
            </div>

            <div class="profile-group-section mb-3">
                <div class="bg-third text-third px-3 py-2">
                    <div class="button px-2" @click="toggleProfilesList()" style="padding-left: 28px !important">
                        <i
                            class="fal fa-building font-size-16"
                            style="position: absolute; top: 7px; left: 8px"
                        ></i>
                        {{ profiles.length }}
                        {{ $root.text((profiles.length == 1) ? $root.contents.profile_title
                            : $root.contents.profiles_title) }}
                    </div>
                    <button
                        v-if="canAddProfiles()"
                        type="button"
                        class="btn btn-secondary py-1 float-right"
                        @click="toggleProfileAddForm()"
                        style="height: 31px"
                    >
                        <i class="far fa-plus"></i> {{ $root.text('Add ' + $root.contents.profile_title, null, true) }}
                    </button>
                </div>
                <div class="profile-add px-4">
                    <profile-edit-form
                        v-if="canAddProfiles()"
                        :profile_fields="profile_fields"
                        :data="{ id: 0, profile_group_id: profile_group.id }"
                        :is_visible="adding_profile"
                        :user_groups="user_groups"
                    ></profile-edit-form>
                </div>
                <div class="profiles-list">
                    <loading v-if="loading_profiles"></loading>
                    <div v-else class="table-responsive mb-0">
                        <table class="table table-centered table-hover">
                            <thead class="thead-light">
                                <tr v-for="item in profiles" :key="item.id">
                                    <th scope="col" class="pt-1 pb-0">
                                        <div class="row">
                                            <div class="col-sm-11 col-10 pl-4" style="padding-top: 9px">
                                                <h5 class="font-size-14 mb-0" style="display: inline-block">
                                                    <a
                                                        href="#"
                                                        class="text-dark"
                                                        @click.prevent="toggleEditProfile(item)"
                                                    >
                                                        {{ item.name }}
                                                    </a>
                                                </h5>
                                                <span v-if="item.updated_by_name" style="font-weight: normal">
                                                    -
                                                    {{ $root.text('edited by {name}', { name: item.updated_by_name }) }}
                                                    {{ $root.text('on {date}', { date: formatDate(item.updated_at) }) }}
                                                </span>
                                                <span v-else style="font-weight: normal">- {{ addedBy(item) }}</span>
                                            </div>
                                            <div class="col-sm-1 col-2 pr-4 contact-links font-size-18">
                                                <a
                                                    href="#"
                                                    class="float-right"
                                                    @click.prevent="toggleEditProfile(item)"
                                                    style="position: relative; top: 5px"
                                                >
                                                    <span
                                                        v-if="(profile_details_open.indexOf(item.id) < 0)"
                                                        :key="'close-' + item.id"
                                                    >
                                                        <i class="fa-regular fa-chevron-circle-down"></i>
                                                    </span>
                                                    <span v-else :key="'open-' + item.id">
                                                        <i class="fa-regular fa-chevron-circle-up"></i>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="row" style="margin-top: 10px">
                                            <div
                                                :class="list_details_class"
                                                :id="'profile-details' + item.id"
                                            >
                                                <profile-edit-form
                                                    :profile_fields="profile_fields"
                                                    :data="item"
                                                    :is_visible="(profile_details_open.indexOf(item.id) > -1)"
                                                    :user_groups="user_groups"
                                                ></profile-edit-form>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>

            <div class="profile-group-section mb-3">
                <div class="bg-third text-third px-3 py-2">
                    <div
                        class="button px-2" @click="toggleUsersList()" style="padding-left: 31px !important">
                        <i
                            class="fal fa-circle-user font-size-16"
                            style="position: absolute; top: 7px; left: 8px"
                        ></i>
                        <span v-if="(users.length == 1)">
                            {{ $root.text(
                                '1 {profile group} user',
                                {
                                    'profile group': $root.text(
                                        $root.contents.profile_group_title,
                                        null,
                                        false,
                                        'profile_group_title'
                                    )
                                }
                            ) }}
                        </span>
                        <span v-else>
                            {{ $root.text(
                                '{x} {profile group} users',
                                {
                                    x: users.length,
                                    'profile group': $root.text(
                                        $root.contents.profile_group_title,
                                        null,
                                        false,
                                        'profile_group_title'
                                    )
                                }
                            ) }}
                        </span>
                    </div>
                    <button
                        v-if="canAddUsers()"
                        type="button"
                        class="btn btn-secondary py-1 float-right"
                        @click="resetUserAddForm()"
                        style="height: 31px"
                    ><i class="far fa-plus"></i> {{ $root.text('Add user', null, true) }}</button>
                </div>
                <div class="users-list">
                    <loading v-if="loading_users"></loading>
                    <div v-else class="table-responsive mb-0">
                        <table class="table table-centered table-hover">
                            <thead class="thead-light">
                                <tr v-for="item in users" :key="item.id">
                                    <th scope="col" class="pt-1 pb-0">
                                        <div class="row">
                                            <div class="col-sm-7 col-5 pl-4" style="padding-top: 9px">
                                                <h5 class="font-size-14 mb-0">
                                                    <a
                                                        href="#"
                                                        class="text-dark"
                                                        @click.prevent="toggleEditUser(item)"
                                                    >
                                                        {{ item.first_name }} {{ item.last_name }}
                                                        <span
                                                            v-if="((item.created_by > 0) && !item.email_verified_at)"
                                                            class="badge badge-info font-size-12 ml-1 mb-0"
                                                        >
                                                            {{ $root.text('Invited on {date}',
                                                                { date: formatDate(item.created_at) }, true) }}
                                                        </span>
                                                    </a>
                                                </h5>
                                            </div>
                                            <div class="col-sm-4 col-5 pl-4" style="padding-top: 9px">
                                                <div class="custom-control custom-radio">
                                                    <input
                                                        v-model="profile_group.primary_contact"
                                                        type="radio"
                                                        :id="'primary-contact' + item.id"
                                                        class="custom-control-input"
                                                        :value="item.id"
                                                        :disabled="!canUpdatePrimaryContact(item)"
                                                        @click="updatePrimaryContact()"
                                                    >
                                                    <label
                                                        class="custom-control-label"
                                                        :for="'primary-contact' + item.id"
                                                    >
                                                        <span v-if="showPrimaryContactLabel(item)">
                                                            {{ $root.text('Primary user', null, true) }}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-sm-1 col-2 pr-4 contact-links font-size-18">
                                                <a
                                                    href="#"
                                                    class="float-right"
                                                    @click.prevent="toggleEditUser(item)"
                                                    style="position: relative; top: 5px"
                                                >
                                                    <span
                                                        v-if="(user_details_open.indexOf(item.id) < 0)"
                                                        :key="'close-' + item.id"
                                                    >
                                                        <i class="fa-regular fa-chevron-circle-down"></i>
                                                    </span>
                                                    <span v-else :key="'open-' + item.id">
                                                        <i class="fa-regular fa-chevron-circle-up"></i>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="row" style="margin-top: 10px">
                                            <div
                                                :class="list_details_class"
                                                :id="'user-details' + item.id"
                                            >
                                                <profile-user-edit-form
                                                    :data="item"
                                                    :is_visible="(user_details_open.indexOf(item.id) > -1)"
                                                    :user_roles="user_roles"
                                                ></profile-user-edit-form>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>

            <div
                v-if="($root.isFeatured('templates') && $root.hasPermission('reports_templates'))"
                class="profile-group-section mb-3"
            >
                <div class="bg-third text-third px-3 py-2">
                    <div
                        class="button px-2" @click="toggleArtworkActivity()" style="padding-left: 31px !important">
                        <i
                            class="fal fa-list-timeline font-size-16"
                            style="position: absolute; top: 7px; left: 8px"
                        ></i>
                        {{ $root.text(
                            '{template} activity',
                            { template: $root.text($root.contents.template_title, null, false, 'template_title') },
                            true
                        ) }}
                    </div>
                    <button
                        type="button"
                        class="btn btn-secondary py-1 float-right"
                        @click="toggleArtworkActivity()"
                        style="height: 31px"
                    >
                        {{ $root.text((showing_artwork_activity ? 'Hide' : 'View') + ' activity', null, true) }}
                    </button>
                </div>
                <div class="artwork-activity">
                    <loading v-if="loading_artwork_activity"></loading>
                    <div v-else>
                        <div v-if="(artwork_activity.length == 0)" class="pt-4 pb-4">
                            <div class="alert alert-warning mx-4 mb-0" role="alert">
                                {{ $root.text('No activity found', null, true) }}
                            </div>
                        </div>
                        <div v-else>
                            <div class="table-responsive mb-0">
                                <table class="table table-centered table-hover">
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col">
                                                <div class="row">
                                                    <div class="col-sm-1 col-12 pb-sm-0 pb-1 pl-4">
                                                        {{ $root.text('No.', null, true) }}
                                                    </div>
                                                    <div class="col-sm-2 col-12 pb-sm-0 pb-1">
                                                        {{ $root.text('Date', null, true) }}
                                                    </div>
                                                    <div class="col-sm-3 col-12 pb-sm-0 pb-1">
                                                        {{ $root.text('Item', null, true) }}
                                                    </div>
                                                    <div class="col-sm-2 col-12 pb-sm-0 pb-1">
                                                        {{ $root.text('Action', null, true) }}
                                                    </div>
                                                    <div class="col-sm-1 col-12 pb-sm-0 pb-1">
                                                        {{ $root.text('Cost', null, true) }}
                                                    </div>
                                                    <div class="col-sm-2 col-12">
                                                        {{ $root.text('Status', null, true) }}
                                                    </div>
                                                    <div class="col-sm-1 d-none d-sm-block pr-4">&nbsp;</div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in artwork_activity_paginated" :key="item.id">
                                            <td
                                                scope="row"
                                                :id="'artwork-activity-row' + item.id"
                                                class="pb-0"
                                            >
                                                <div class="row">
                                                    <div class="col-1 d-none d-sm-block pl-4" style="padding-top: 3px">
                                                        {{ item.resource_id }}
                                                    </div>
                                                    <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                                        {{ item.created_at_formatted }}
                                                    </div>
                                                    <div class="col-3 d-none d-sm-block" style="padding-top: 3px">
                                                        {{ item.resource_title }}
                                                    </div>
                                                    <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                                        {{ item.action }}
                                                    </div>
                                                    <div class="col-1 d-none d-sm-block" style="padding-top: 3px">
                                                        {{ item.total_price_formatted || '&nbsp;' }}
                                                    </div>
                                                    <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                                        {{ item.order_status_formatted || '&nbsp;' }}
                                                    </div>
                                                    <div class="col-10 d-sm-none pl-4">
                                                        <p class="mb-1">{{ item.resource_id }}</p>
                                                        <p class="mb-1">{{ item.created_at_formatted }}</p>
                                                        <p class="mb-1">{{ item.resource_title }}</p>
                                                        <p class="mb-1">{{ item.action }}</p>
                                                        <p class="mb-1">{{ item.total_price_formatted || '&nbsp;' }}</p>
                                                        <p class="mb-0">{{ item.order_status_formatted || '&nbsp;' }}</p>
                                                    </div>
                                                    <div class="col-sm-1 col-2 font-size-18 contact-links pr-4">
                                                        <a
                                                            href="#"
                                                            class="float-right"
                                                            @click.prevent="toggleViewArtworkActivity(item)"
                                                        >
                                                            <span
                                                                v-if="(artwork_activity_details_open
                                                                    .indexOf(item.id) < 0)"
                                                                :key="'close-' + item.id"
                                                            >
                                                                <i class="fa-regular fa-chevron-circle-down"></i>
                                                            </span>
                                                            <span v-else :key="'open-' + item.id">
                                                                <i class="fa-regular fa-chevron-circle-up"></i>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="row list-details-td mt-2 pl-2 pr-2">
                                                    <div
                                                        class="col-12 list-details"
                                                        :id="'artwork-activity-details' + item.id"
                                                    >
                                                        <artwork-history-details
                                                            :data="item"
                                                            :is_visible="(artwork_activity_details_open
                                                                .indexOf(item.id) > -1)"
                                                        ></artwork-history-details>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="canUpdateProfileGroup()"
            class="modal fade"
            id="profile-group-edit-form-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="profile-group-edit-form-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <profile-group-edit-form :data="profile_group"></profile-group-edit-form>
            </div>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    export default {
        data () {
            return {
                component_name: 'view-profile-group',
                loading: true,
                profile_group_loaded: false,
                errors: false,
                profile_group: {},
                profile_fields: [],
                profiles: [],
                users: [],
                artwork_activity: [],
                adding_profile: false,
                loading_profiles: true,
                showing_profiles: false,
                profile_details_open: [],
                loading_users: true,
                showing_users: false,
                user_details_open: [],
                loading_artwork_activity: true,
                showing_artwork_activity: false,
                artwork_activity_details_open: [],
                updating_primary_contact: false,
                original_primary_contact: null,
                user_roles: [],
                list_details_class: 'col-12 list-details font-weight-normal bg-white mt-1 mb-0 px-3 py-0',
                artwork_activity_per_page: 25,
                artwork_activity_paginated: [],
                artwork_activity_page: 0,
                artwork_activity_paginating: false,
                stats: {
                    visits: {
                        loading: true
                    },
                    registrations: {
                        loading: true
                    },
                    templates: {
                        loading: true
                    }
                },
                user_groups: []
            }
        },
        created() {
            if (this.$root.isFeatured('templates') && this.$root.hasPermission('reports_templates')) {
                this.handleDebouncedScroll = debounce(this.handleScroll, 100);
                window.addEventListener('scroll', this.handleDebouncedScroll);
            }
        },
        mounted () {
            $('body').removeClass('login-body');
            
            /*if (!this.$root.hasPermission('printing_manage_suppliers')) {
                this.errors = 'Sorry, you do not have access to this function.';

                return false;
            }*/
            this.$root.is_own_profile_group = false;
            this.getProfileGroup();
            this.getProfileFields();
            this.getProfiles();
            this.getUsers();
            this.getUserRoles();
            this.getUserGroups();

            if (this.$root.isFeatured('templates') && this.$root.hasPermission('reports_templates')) {
                this.getArtworkActivity();
            }
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleDebouncedScroll);
        },
        watch: {
            adding_profile: function (val) {
                if (val) {
                    setTimeout(function () {
                        $('.profile-add').slideDown('slow');
                    }, 100);

                    return null;
                }
                $('.profile-add').slideUp('slow');
            },
            showing_profiles: function (val) {
                if (val) {
                    if (this.profiles.length == 0) {
                        this.showing_profiles = false;

                        return null;
                    }
                    this.adding_profile = false;
                    setTimeout(function () {
                        $('.profiles-list').slideDown('slow');
                    }, 100);

                    return null;
                }
                $('.profiles-list').slideUp('slow');
            },
            showing_users: function (val) {
                if (val) {
                    if (this.users.length == 0) {
                        this.showing_users = false;

                        return null;
                    }
                    setTimeout(function () {
                        $('.users-list').slideDown('slow');
                    }, 100);

                    return null;
                }
                $('.users-list').slideUp('slow');
            },
            showing_artwork_activity: function (val) {
                if (val) {
                    /*if (this.users.length == 0) {
                        this.showing_users = false;

                        return null;
                    }*/
                    setTimeout(function () {
                        $('.artwork-activity').slideDown('slow');
                    }, 100);

                    return null;
                }
                $('.artwork-activity').slideUp('slow');
            },
            artwork_activity_page: function (val) {
                if (val) {
                    const indexStart = this.artwork_activity_paginated.length;
                    let indexEnd = (val * this.artwork_activity_per_page) - 1;
                    if (indexEnd > (this.artwork_activity.length - 1)) {
                        indexEnd = this.artwork_activity.length - 1;
                    }
                    if (indexEnd < indexStart) {
                        return null;
                    }
                    for (let index = indexStart; index <= indexEnd; index++) {
                        this.artwork_activity_paginated.push(this.artwork_activity[index]);
                    }

                    return null;
                }
                this.artwork_activity_page = 1;
            },
            artwork_activity_paginating: function (val) {
                if (val) {
                    var vm = this;
                    setTimeout(function () {
                        vm.artwork_activity_paginating = false;
                    }, 500);
                }
            }
        },
        methods: {
            getProfileGroup: function () {
                //this.loading = true;
                this.errors = false;
                this.stats = {
                    visits: {
                        loading: true
                    },
                    registrations: {
                        loading: true
                    },
                    templates: {
                        loading: true
                    }
                };
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile-group/'
                    + this.$route.params.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.profile_group = response.data.profile_group;
                    vm.$root.view_profile_group_name = vm.profile_group.name;
                    vm.original_primary_contact = vm.profile_group.primary_contact;
                    vm.profile_group_loaded = true;
                    vm.getStats();
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            getStats: function (stat = null, dateRange = null) {
                if (!stat) {
                    if (this.$root.hasPermission('reports_overview') || this.$root.hasPermission('reports_users')) {
                        this.getStats('visits', this.getDateRange());
                        this.getStats('registrations', this.getDateRange());
                    }
                    if (
                        this.$root.isFeatured('templates')
                        && (
                            this.$root.hasPermission('reports_overview') || this.$root.hasPermission('reports_templates')
                        )
                    ) {
                        this.getStats('templates', this.getDateRange());
                    }

                    return null;
                }
                var vm = this;
                if (stat == 'visits') {
                    let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/report/visits'
                        + '/admin|count|gr::day|pg::' + this.profile_group.id + '|dr::' + dateRange;
                    
                    /**
                     * Send get report counts request to API.
                     */
                    axios({
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        if (!vm.stats.visits.data) {
                            let values = [];
                            response.data.visits.data.forEach((item) => {
                                values.push(item.value);
                            });
                            vm.stats.visits.data = values;
                            vm.stats.visits.total = response.data.visits.total;
                            vm.getStats('visits', vm.getDateRange(true));

                            return null;
                        }
                        if (response.data.visits.total == 0) {
                            vm.stats.visits.increase = (vm.stats.visits.total > 0) ? 100 : 0;
                        } else {
                            const difference = vm.stats.visits.total - response.data.visits.total;
                            vm.stats.visits.increase = (difference / response.data.visits.total) * 100;
                        }
                        
                        vm.stats.visits.loading = false;
                    })
                    .catch(function (error) {
                        //
                    });

                    return null;
                }
                if (stat == 'registrations') {
                    let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/report/registrations'
                        + '/count|gr::day|pg::' + this.profile_group.id + '|dr::' + dateRange;
                    
                    /**
                     * Send get report counts request to API.
                     */
                    axios({
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        if (!vm.stats.registrations.data) {
                            let values = [];
                            response.data.registrations.data.forEach((item) => {
                                values.push(item.value);
                            });
                            vm.stats.registrations.data = values;
                            vm.stats.registrations.total = response.data.registrations.total;
                            vm.getStats('registrations', vm.getDateRange(true));

                            return null;
                        }
                        if (response.data.registrations.total == 0) {
                            vm.stats.registrations.increase = (vm.stats.registrations.total > 0) ? 100 : 0;
                        } else {
                            const difference = vm.stats.registrations.total - response.data.registrations.total;
                            vm.stats.registrations.increase = (difference / response.data.registrations.total) * 100;
                        }
                        
                        vm.stats.registrations.loading = false;
                    })
                    .catch(function (error) {
                        //
                    });

                    return null;
                }
                if (stat == 'templates') {
                    let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/report/templates'
                        + '/count|gr::day|pg::' + this.profile_group.id + '|dr::' + dateRange + '|tp::!shares';
                    
                    /**
                     * Send get report counts request to API.
                     */
                    axios({
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        if (!vm.stats.templates.data) {
                            let values = [];
                            response.data.templates.data.forEach((item) => {
                                values.push(item.value.downloads + item.value.orders);
                            });
                            vm.stats.templates.data = values;
                            vm.stats.templates.total = response.data.templates.total.downloads
                                + response.data.templates.total.orders;
                            vm.getStats('templates', vm.getDateRange(true));

                            return null;
                        }
                        const total = response.data.templates.total.downloads + response.data.templates.total.orders;
                        if (total == 0) {
                            vm.stats.templates.increase = (vm.stats.templates.total > 0) ? 100 : 0;
                        } else {
                            const difference = vm.stats.templates.total - total;
                            vm.stats.templates.increase = (difference / total) * 100;
                        }
                        
                        vm.stats.templates.loading = false;
                    })
                    .catch(function (error) {
                        //
                    });

                    return null;
                }
            },
            getDateRange: function (lastMonth = false) {
                let dateTo = new Date();
                if (lastMonth) {
                    dateTo = new Date(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate() - 30);
                }
                let month = ('0' + (dateTo.getMonth() + 1)).slice(-2);
                let day = ('0' + dateTo.getDate()).slice(-2);
                const dateToF = dateTo.getFullYear() + '-' + month + '-' + day;
                const dateFrom = new Date(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate() - 29);
                month = ('0' + (dateFrom.getMonth() + 1)).slice(-2);
                day = ('0' + dateFrom.getDate()).slice(-2);
                const dateFromF = dateFrom.getFullYear() + '-' + month + '-' + day;

                return dateFromF + '-' + dateToF;
            },
            getProfiles: function () {
                this.loading_profiles = true;
                this.adding_profile = false;
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profiles/pg::'
                    + this.$route.params.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.profile_details_open = [];
                    vm.profiles = response.data.profiles;

                    if (!vm.$root.user.profile_id) {
                        vm.$root.is_own_profile_group = false;

                        return null;
                    }
                    let found = false;
                    response.data.profiles.forEach((item) => {
                        if (item.id == vm.$root.user.profile_id) {
                            found = true;
                        }
                    });
                    vm.$root.is_own_profile_group = found;
                })
                .catch(function (error) {
                    //
                })
                .finally(() => {
                    vm.loading_profiles = false;
                });
            },
            getProfileFields: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile-fields';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.profile_fields = response.data.profile_fields;
                })
                .catch(function (error) {
                    //
                });
            },
            getUsers: function () {
                this.loading_users = true;
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/users/!export|!pagination|!deleted|pg::' + this.$route.params.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.user_details_open = [];
                    vm.users = response.data.users;
                })
                .catch(function (error) {
                    //
                })
                .finally(() => {
                    vm.loading_users = false;
                });
            },
            getArtworkActivity: function () {
                this.loading_artwork_activity = true;
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile-group/'
                    + this.$route.params.id + '/artwork-usage-history';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.artwork_activity_details_open = [];
                    vm.artwork_activity = response.data.artwork_history;
                    vm.artwork_activity_paginated = [];
                    vm.artwork_activity_page = 1;
                    vm.artwork_activity_paginating = true;
                })
                .catch(function (error) {
                    //
                })
                .finally(() => {
                    vm.loading_artwork_activity = false;
                });
            },
            formatDate: function (rawDate) {
                const date = rawDate.substr(0, 10).split('-');

                return date[2] + '/' + date[1] + '/' + date[0];
            },
            toggleProfilesList: function () {
                this.showing_profiles = !this.showing_profiles;
            },
            toggleUsersList: function () {
                this.showing_users = !this.showing_users;
            },
            toggleArtworkActivity: function () {
                this.showing_artwork_activity = !this.showing_artwork_activity;
            },
            toggleProfileAddForm: function () {
                this.adding_profile = !this.adding_profile;
            },
            toggleEditProfile: function (item) {
                if (this.profile_details_open.indexOf(item.id) < 0) {
                    this.profile_details_open.push(item.id);
                    $('#profile-details' + item.id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#profile-details' + item.id).slideUp('slow', function () {
                        for (var n = 0; n < vm.profile_details_open.length; n++) { 
                            if (vm.profile_details_open[n] == item.id) { 
                                vm.profile_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            },
            toggleEditUser: function (item) {
                if (this.user_details_open.indexOf(item.id) < 0) {
                    this.user_details_open.push(item.id);
                    $('#user-details' + item.id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#user-details' + item.id).slideUp('slow', function () {
                        for (var n = 0; n < vm.user_details_open.length; n++) { 
                            if (vm.user_details_open[n] == item.id) { 
                                vm.user_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            },
            resetUserAddForm: function () {
                if (this.profiles.length == 0) {
                    this.$parent.message = {
                        type: 'error',
                        title: this.$root.text('Add user', null, true),
                        text: this.$root.text(
                            'There are no ' + this.$root.contents.profiles_title + ' added to this '
                                + '{profile group} yet.',
                            {
                                'profile group': this.$root.text(
                                    this.$root.contents.profile_group_title,
                                    null,
                                    false,
                                    'profile_group_title'
                                )
                            },
                            true
                        ) + ' ' + this.$root.text(
                            'Please add a ' + this.$root.contents.profile_title + ' first.',
                            null,
                            true
                        )
                    };
                    $('#message-modal').modal('show');

                    return null;
                }
                const component = this.$root.findComponent(this.$parent, 'user-add-form');
                if (component) {
                    component.resetForm('profile-group-user', this.profiles[0]);
                    $('#user-add-form-modal').modal('show');
                }
            },
            canUpdatePrimaryContact: function (user = null) {
                if (this.updating_primary_contact) {
                    return false;
                }
                if (user) {
                    if (user.id == this.profile_group.primary_contact) {
                        return true;
                    }
                }

                return this.canUpdateProfileGroup();
            },
            showPrimaryContactLabel: function (user) {
                if (user.id == this.profile_group.primary_contact) {
                    return true;
                }
                if (!this.profile_group.primary_contact && (user.id == this.users[0].id)) {
                    return true;
                }

                return false;
            },
            updatePrimaryContact: function (delay = true) {
                var vm = this;
                if (delay) {
                    setTimeout(function () {
                        vm.updatePrimaryContact(false);
                    }, 500);
                }
                if (
                    !this.canUpdatePrimaryContact()
                    || (this.profile_group.primary_contact == this.original_primary_contact)
                ) {
                    return null;
                }
                this.updating_primary_contact = true;
                
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile-group/'
                    + this.profile_group.id;

                axios({
                    method: 'put',
                    url: url,
                    data: this.profile_group,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.getProfileGroup();
                })
                .catch(function (error) {
                    vm.profile_group.primary_contact = vm.original_primary_contact;
                    $('html, body').animate({ scrollTop: 0 }, 350);
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error
                                    .replace(/profile groups/g, vm.$root.contents.profile_groups_title)
                                    .replace(/profile group/g, vm.$root.contents.profile_group_title);
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                let errorHtml = null;
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    errorHtml = value.join('<br>') + '<br>';
                                    vm.errors += errorHtml.replace(
                                        /subfolder id/g, vm.$root.contents.subfolder_title
                                    )
                                    .replace(
                                        /description/g, vm.$root.contents.profile_group_description_title
                                    );
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating_primary_contact = false
                );
            },
            getUserRoles: function () {
                /**
                 * Send get roles request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/settings/roles';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.user_roles = response.data.roles;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            addedBy: function (profile = null) {
                if (profile) {
                    if (profile.created_by_name) {
                        return this.$root.text('added by') + ' ' + profile.created_by_name + ' '
                            + this.$root.text('on {date}', { date: this.formatDate(profile.created_at) });
                    }

                    return this.$root.text('date added:') + ' ' + this.formatDate(profile.created_at);
                }
                if (this.profile_group.created_by_name) {
                    return this.$root.text('Added by: {name}', { name: this.profile_group.created_by_name }, true) + ' '
                        + this.$root.text('on {date}', { date: this.formatDate(this.profile_group.created_at) });
                }

                return this.$root.text('Date added:', null, true) + ' ' + this.formatDate(this.profile_group.created_at);
            },
            toggleViewArtworkActivity: function (item) {
                if (this.artwork_activity_details_open.indexOf(item.id) < 0) {
                    this.artwork_activity_details_open.push(item.id);
                    $('#artwork-activity-row' + item.id).addClass('highlighted');
                    $('#artwork-activity-details' + item.id).slideDown('slow');

                    return null;
                }
                var vm = this;
                $('#artwork-activity-details' + item.id).slideUp('slow', function () {
                    $('#artwork-activity-row' + item.id).removeClass('highlighted');
                    for (var n = 0; n < vm.artwork_activity_details_open.length; n++) { 
                        if (vm.artwork_activity_details_open[n] == item.id) { 
                            vm.artwork_activity_details_open.splice(n, 1); 
                        }
                    }
                });
            },
            handleScroll(event) {
                if (!this.showing_artwork_activity || this.artwork_activity_paginating) {
                    return null;
                }
                const pageScrollTop = $('html, body').scrollTop();
                const appHeight = $('#app').outerHeight();
                const windowHeight = $(window).height();
                const scrollPosition = appHeight - (pageScrollTop + windowHeight);
                if (scrollPosition < 200) {
                    this.artwork_activity_paginating = true;
                    this.artwork_activity_page++;
                }
            },
            canUpdateProfileGroup: function () {
                if (this.$root.hasPermission('profiles_groups_edit')) {
                    return true;
                }
                
                return (this.$root.is_own_profile_group && this.$root.hasPermission('profiles_own_group_edit'));
            },
            canAddProfiles: function () {
                if (this.$root.hasPermission('profiles_profiles_add')) {
                    return true;
                }

                return (this.$root.is_own_profile_group && this.$root.hasPermission('profiles_own_group_add_profiles'));
            },
            canAddUsers: function () {
                if (this.$root.hasPermission('users_invite')) {
                    return true;
                }

                return (this.$root.is_own_profile_group && this.$root.hasPermission('profiles_own_group_add_users'));
            },
            getMonthName: function () {
                const monthNames = [
                    this.$root.text('January', null, true),
                    this.$root.text('February', null, true),
                    this.$root.text('March', null, true),
                    this.$root.text('April', null, true),
                    this.$root.text('May', null, true),
                    this.$root.text('June', null, true),
                    this.$root.text('July', null, true),
                    this.$root.text('August', null, true),
                    this.$root.text('September', null, true),
                    this.$root.text('October', null, true),
                    this.$root.text('November', null, true),
                    this.$root.text('December', null, true)
                ];
                const date = new Date();

                return monthNames[date.getMonth()];
            },
            getUserGroups: function () {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user-groups/!private';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.user_groups = response.data.user_groups;
                })
                .catch(function (error) {
                    // Do anything?
                });
            }
        }
    }
</script>
<style scoped>
    .profile-add, .profiles-list, .users-list, .artwork-activity {
        display: none;
        background-color: white;
    }
</style>
