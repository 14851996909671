<template>
    <div>
        <form v-on:submit.prevent="onSubmit()">
            <!--<h5 class="mb-3 mt-2 mt-sm-0">
                Edit {{ profile_field.title }}{{ profile_field.title_suffix }}
            </h5>-->

            <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label
                            :for="'title' + profile_field.id"
                            :id="'title-label' + profile_field.id"
                            class="mandatory-label"
                        >{{ $root.text('Field title', null, true) }} *</label>
                        <div class="input-group">
                            <input
                                v-model="profile_field.title"
                                type="text"
                                :id="'title' + profile_field.id"
                                class="form-control mandatory-field"
                                :readonly="updating"
                                v-on:keyup="$root.unHighlightErrors('title', profile_field.id)"
                                v-on:change="$root.unHighlightErrors('title', profile_field.id)"
                            >
                            <div class="input-group-append">
                                <select
                                    v-if="$root.client.artwork_generate_qr_codes"
                                    v-model="title_suffix"
                                    class="form-control"
                                >
                                    <option v-for="suffix in suffixes" :key="suffix.id" :value="suffix.name">
                                        {{ suffix.name }}
                                    </option>
                                </select>
                                <span v-else class="input-group-text dark bg-dark text-white">
                                    {{ profile_field.title_suffix }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label
                            :for="'type' + profile_field.id"
                            :id="'type-label' + profile_field.id"
                        >
                            {{ $root.text('Field type', null, true) }} *
                        </label>
                        <searchable-option-list
                            v-model="field_type_id"
                            :value="field_type_id"
                            :options="fieldTypeOptions()"
                            :field_id="'type' + profile_field.id"
                            :readonly="updating"
                            v-on:change="$root.unHighlightErrors('type', profile_field.id)"
                        ></searchable-option-list>
                    </div>
                </div>
                <div v-if="profile_field.type == 'I'" class="col-lg-6">
                    <div class="form-group">
                        <label
                            :for="'min_pixels' + profile_field.id"
                            :id="'min_pixels-label' + profile_field.id"
                            class="mandatory-label"
                        >{{ $root.text('Minimum size', null, true) }}</label>
                        <div class="custom-control custom-checkbox float-right mr-1">
                            <input
                                v-model="no_min_pixels"
                                type="checkbox"
                                class="custom-control-input"
                                :id="'no_min_pixels' + profile_field.id"
                                :disabled="updating"
                            >
                            <label class="custom-control-label" :for="'no_min_pixels' + profile_field.id">
                                {{ $root.text('No minimum size', null, true) }}
                            </label>
                        </div>
                        <div class="input-group">
                            <input
                                v-model="profile_field.min_pixels"
                                type="number"
                                :id="'min_pixels' + profile_field.id"
                                class="form-control"
                                :readonly="updating || no_min_pixels"
                                min="1"
                                step="1"
                                v-on:keyup="checkMinPixels()"
                                v-on:change="checkMinPixels()"
                            >
                            <div class="input-group-append">
                                <span class="input-group-text">
                                    {{ $root.text('{x} px (wide or deep)', { x : '' }) }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="profile_field.type == 'I'" class="col-lg-6"></div>
                <div class="col-12">
                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input
                                v-model="profile_field.required"
                                type="checkbox"
                                class="custom-control-input"
                                :id="'required' + profile_field.id"
                                :disabled="updating"
                            >
                            <label class="custom-control-label" :for="'required' + profile_field.id">
                                {{ $root.text('Required', null, true) }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <span v-if="!updating">
                        <button
                            type="button"
                            class="btn btn-primary mr-2"
                            :disabled="updating"
                            @click="onSubmit()"
                        >
                            <i class="far fa-save mr-1"></i> {{ $root.text('Save', null, true) }}
                        </button>
                    </span>
                    <span v-else>
                        <button type="button" class="btn btn-primary mr-2">
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">{{ $root.text('Save', null, true) }}</span>
                        </button>
                    </span>

                    <button
                        v-if="$root.hasPermission('profiles_fieldset_delete')"
                        type="button"
                        class="btn btn-secondary mr-2"
                        :disabled="updating"
                        @click="$parent.$parent.deleteProfileField(profile_field)"
                    >
                        <i class="far fa-trash-alt mr-1"></i> {{ $root.text('Delete', null, true) }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        props: ['data', 'field_types', 'update', 'cancel'],
        data () {
            return {
                errors: false,
                updating: false,
                profile_field: {},
                profile_field_options: [],
                original_data: {},
                field_type_id: null,
                field_type_name: '',
                counter: 0,
                suffixes: [
                    {
                        id: 1,
                        name: '_L'
                    },
                    {
                        id: 2,
                        name: '_LQR'
                    }
                ],
                no_min_pixels: false,
                title_suffix: '_L'
            }
        },
        mounted () {
            if (this.data) {
                this.original_data = this.data;
                this.updateOriginalData();
            }
        },
        watch: {
            data: function (val) {
                this.original_data = val;
                this.updateOriginalData();
            },
            update: function (val) {
                if (val == this.profile_field.id) {
                    this.onSubmit();
                }
            },
            cancel: function (val) {
                if (val == this.profile_field.id) {
                    this.original_data.edit_mode = false;
                    this.$parent.$parent.profile_fields.push({ id: -1 });
                    this.$parent.$parent.profile_fields.pop();
                    this.$parent.$parent.cancel_item = 0;
                }
            },
            field_type_id: function (val) {
                if (val == 'text') {
                    this.profile_field.type = 'T';
                    this.profile_field.single_line = true;

                    return null;
                }
                this.title_suffix = '_L';
                if (val == 'textarea') {
                    this.profile_field.type = 'T';
                    this.profile_field.single_line = false;

                    return null;
                }
                if (val == 'image') {
                    this.profile_field.type = 'I';
                    this.profile_field.single_line = true;

                    return null;
                }
                this.profile_field.type = null;
                this.profile_field.single_line = null;
            },
            no_min_pixels: function (val) {
                if (val) {
                    this.profile_field.min_pixels = null;

                    return null;
                }
                this.profile_field.min_pixels = 500;
            },
            title_suffix: function (val) {
                this.profile_field.title_suffix = val;
                if (val == '_LQR') {
                    this.field_type_id = 'text';
                }
            }
        },
        methods: {
            onSubmit: function () {
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                this.errors = false;
                this.updating = true;
                this.$parent.$parent.update_item = this.profile_field.id;

                /**
                 * Send update profile field request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile-field/'
                    + this.profile_field.id;
                const data = {
                    title: this.profile_field.title,
                    title_suffix: this.profile_field.title_suffix || null,
                    type: this.profile_field.type,
                    single_line: this.profile_field.single_line,
                    required: this.profile_field.required || false,
                    min_pixels: this.no_min_pixels ? null : this.profile_field.min_pixels
                }
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    for (const [key, value] of Object.entries(this.profile_field)) {
                        if (key != 'edit_mode') {
                            this.original_data[key] = value;
                        }
                    }
                    vm.original_data.edit_mode = false;
                    vm.$parent.$parent.profile_fields.push({ id: -1 });
                    vm.$parent.$parent.profile_fields.pop();
                    vm.$root.profile_fields_changed = true;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                vm.errors += value.join('<br>') + '<br>';
                                $('#' + key + '-label' + vm.profile_field.id).addClass('text-danger');
                                $('#' + key + vm.profile_field.id).addClass('field-error');
                            }
                        }
                    }
                })
                .finally(() => {
                    vm.updating = false;
                    vm.$parent.$parent.update_item = 0;
                });
            },
            updateOriginalData: function () {
                this.updating = true;
                const profileField = this.original_data;
                this.field_types.forEach((item) => {
                    if (
                        ((item.id == 'image') && (profileField.type == 'I'))
                        || ((item.id == 'text') && (profileField.type == 'T') && profileField.single_line)
                        || ((item.id == 'textarea') && (profileField.type == 'T') && !profileField.single_line)
                    ) {
                        this.field_type_id = item.id;
                        this.field_type_name = item.title.toLowerCase();
                    }
                });
                for (const [key, value] of Object.entries(profileField)) {
                    this.profile_field[key] = value;
                    if (key == 'min_pixels') {
                        this.no_min_pixels = value ? false : true;
                    }
                    if (key == 'title_suffix') {
                        this.title_suffix = value;
                    }
                }
                this.updating = false;
            },
            fieldTypeOptions: function () {
                if (!this.field_types) {
                    return [];
                }
                let options = [];
                this.field_types.forEach((item) => {
                    options.push({ id: item.id, name: item.title });
                });

                return options;
            },
            checkMinPixels: function () {
                if (this.no_min_pixels) {
                    return null;
                }
                if (isNaN(this.profile_field.min_pixels)) {
                    this.updating = true;
                    this.profile_field.min_pixels = 1;
                    this.updating = false;

                    return null;
                }
                if (this.profile_field.min_pixels < 1) {
                    this.updating = true;
                    this.profile_field.min_pixels = 1;
                    this.updating = false;
                }
            }
        }
    }
</script>
<style scoped>
    .input-group-text.dark {
        border-top-color: #343a40;
        border-right-color: #343a40;
        border-bottom-color: #343a40;
    }
</style>
